import React from "react"
import { createSvgIcon } from "@material-ui/core/utils"

export default createSvgIcon(
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Artboard-Copy-2" transform="translate(0.000000, 0.000000)" fill="#4C7DA3" fillRule="nonzero">
      <g id="male" transform="translate(0.000000, 0.000000)">
        <path
          d="M14.5605469,0 L10.4589844,0 C10.2162891,0 10.0195312,0.196757812 10.0195312,0.439453125 C10.0195312,0.682148437 10.2162891,0.87890625 10.4589844,0.87890625 L13.4996191,0.87890625 L8.42821289,5.9503125 C7.50527344,5.17098633 6.34886719,4.74609375 5.12695312,4.74609375 C3.7575,4.74609375 2.47001953,5.27938477 1.50164062,6.24773437 C0.533261719,7.21608398 0,8.50359375 0,9.87304687 C0,11.2425 0.533291016,12.5299805 1.50164062,13.4983594 C2.46999023,14.4667383 3.7575,15 5.12695312,15 C6.49640625,15 7.78388672,14.466709 8.75226562,13.4983594 C9.72064453,12.5300098 10.2539062,11.2425 10.2539062,9.87304687 C10.2539062,8.65113281 9.82901367,7.49472656 9.0496875,6.57178711 L14.1210937,1.50038086 L14.1210937,4.54101562 C14.1210937,4.78371094 14.3178516,4.98046875 14.5605469,4.98046875 C14.8032422,4.98046875 15,4.78371094 15,4.54101562 L15,0.439453125 C15,0.196757812 14.8032422,0 14.5605469,0 Z M8.13079102,12.8768848 C7.3284375,13.679209 6.26165039,14.1210937 5.12695313,14.1210937 C3.99225586,14.1210937 2.92546875,13.679209 2.12311523,12.8768848 C1.32079102,12.0745312 0.87890625,11.0077441 0.87890625,9.87304687 C0.87890625,8.73834961 1.32079102,7.6715625 2.12311523,6.86920898 C2.92546875,6.06688477 3.99225586,5.625 5.12695313,5.625 C6.26165039,5.625 7.3284375,6.06688477 8.13079102,6.86920898 C8.93311523,7.6715625 9.375,8.73834961 9.375,9.87304687 C9.375,11.0077441 8.93311523,12.0745312 8.13079102,12.8768848 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </g>,
  "Man"
)
