import React from "react"
import { Container, Grid, Box, Typography } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import AvatarGroup from "../../components/AvatarGroup"
import LaunchIcon from "@material-ui/icons/Launch"

function InfoVersicherung() {
  return (
    <Container maxWidth="sm" style={{ padding: 0, backgroundColor: "white" }}>
      <AppBar text="Krankenversicherung für Studenten" back="/info" />

      <Box my={4}>
        <Grid container style={{ marginTop: "3rem", marginBottom: "1rem", padding: "1rem" }}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="primary" style={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
              <b>Wie bin ich als nicht deutscher Student krankenversichert?</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <div style={{ paddingBottom: "4rem" }}>
              <Typography variant="body1">
                Jeder, der an einer Universität in Deutschland studieren möchte, benötigt eine Krankenversicherung. Die
                Krankenversicherungspflicht gilt auch für Studierende aus dem EU-Ausland (EU/EWR). Der Nachweis über eine
                Krankenversicherung ist für die Immatrikulation an einer deutschen Universität erforderlich. Studenten und Studentinnen aus
                dem EU-Ausland legen ihre Europäische Krankenversicherungskarte (EHIC) vor. Dies ist in der Regel ausreichend.
                <br />
                <br />
                Erasmus-Studenten bleiben während ihrer Studienzeit in Deutschland bei ihrer Krankenkasse im Heimatland gesetzlich
                versichert. Eine deutsche Krankenversicherung ist nicht nötig, da Erasmus-Studenten an ihrer Universität im Heimatland
                immatrikuliert bleiben und nach ihrem ein- bis zwei semestrigen Aufenthalt dort das Studium fortsetzen. Um den
                Krankenversicherungsschutz aus dem Ausland in Deutschland zu nutzen, benötigen die Studenten die Europäische
                Krankenversicherungskarte (EHIC). Aufgrund von Sozialversicherungsabkommen zwischen Deutschland und anderen Mitgliedsländern
                der Europäischen Union oder des Europäischen Wirtschaftsraums (EWR) ist diese in Deutschland gültig. Die EHIC befindet sich
                auf der Rückseite der Versichertenkarte oder kann bei der heimischen Krankenversicherung beantragt werden. Bei der
                Immatrikulation kann die Europäische Krankenversicherungskarte als Nachweis eingereicht werden.
                <br />
                <br />
                Dieser Text stammt von der Webseite krankenkasse.de:
                <br />
              </Typography>

              <span>
                <LaunchIcon color="primary" style={{ fontSize: "0.725rem" }} />{" "}
                <Typography variant="caption" color="primary" style={{ paddingTop: "-2.25rem", fontStyle: "italic" }}>
                  https://www.krankenkassenzentrale.de/meine-krankenkasse/student/ehic
                </Typography>
              </span>

              <Typography variant="body1" style={{ marginTop: "1rem" }}>
                Weitere Interessante Infos findest du unter folgenden Links:
              </Typography>

              <span>
                <LaunchIcon color="primary" style={{ fontSize: "0.725rem" }} />{" "}
                <Typography variant="caption" color="primary" style={{ fontStyle: "italic" }}>
                  https://www.krankenkassenzentrale.de/wiki/incoming-de#studenten
                </Typography>
              </span>

              <div style={{ marginTop: "1rem" }}>
                <AvatarGroup max={6} avatars={["/static/images/flags/100px/de.png", "/static/images/flags/100px/gb.png"]} />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>

      <NavBar active={3} />
    </Container>
  )
}

export default InfoVersicherung
