import React from "react"
import { createSvgIcon } from "@material-ui/core/utils"

export default createSvgIcon(
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Artboard" transform="translate(0.000000, 0.000000)" fill="#4C7DA3" fillRule="nonzero">
      <g id="divers" transform="translate(0.000000, 0.000000)">
        <path
          d="M10,10.1464844 C10,7.30871289 7.757,5 5,5 C2.243,5 0,7.30871289 0,10.1464844 C0,12.816248 1.98532258,15.0174492 4.51612903,15.2687969 L4.51612903,18.6796875 L2.74193548,18.6796875 C2.47470968,18.6796875 2.25806452,18.9026797 2.25806452,19.1777344 C2.25806452,19.4527891 2.47470968,19.6757812 2.74193548,19.6757812 L4.51612903,19.6757812 L4.51612903,21.5019531 C4.51612903,21.7770078 4.73277419,22 5,22 C5.26722581,22 5.48387097,21.7770078 5.48387097,21.5019531 L5.48387097,19.6757812 L7.25806452,19.6757812 C7.52529032,19.6757812 7.74193548,19.4527891 7.74193548,19.1777344 C7.74193548,18.9026797 7.52529032,18.6796875 7.25806452,18.6796875 L5.48387097,18.6796875 L5.48387097,15.2687969 C8.01467742,15.0174492 10,12.816248 10,10.1464844 Z M0.967741935,10.1464844 C0.967741935,7.85795898 2.7766129,5.99609375 5,5.99609375 C7.2233871,5.99609375 9.03225806,7.85795898 9.03225806,10.1464844 C9.03225806,12.4350098 7.2233871,14.296875 5,14.296875 C2.7766129,14.296875 0.967741935,12.4350098 0.967741935,10.1464844 Z"
          id="Shape"
        ></path>
        <path
          d="M14.5320433,0 L10.164447,0 C9.90601011,0 9.69649027,0.209578409 9.69649027,0.468087572 C9.69649027,0.726596736 9.90601011,0.936175145 10.164447,0.936175145 L13.4023021,0.936175145 L8.00195652,6.33803055 C7.95817355,6.33803055 8.66374095,7 8.66374095,7 L14.0640865,1.59814459 L14.0640865,4.83690492 C14.0640865,5.09541408 14.2736064,5.30499249 14.5320433,5.30499249 C14.7904802,5.30499249 15,5.09541408 15,4.83690492 L15,0.468087572 C15,0.209578409 14.7904802,0 14.5320433,0 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </g>,
  "Diverse"
)
