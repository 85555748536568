import React, { useState } from "react"
import { TextField, Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"

function Step2() {
  const [born, setBorn] = useState("1986-01-01")
  const [old, setOld] = useState(34)
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">Ich bin geboren am:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="1986-01-01"
          type="date"
          value={born}
          onChange={(e) => {
            const dateSplitted = e.target.value.split("-")
            const now = new Date()
            const curentYear = now.getFullYear()
            const old = curentYear - parseInt(dateSplitted[0])
            setBorn(e.target.value)
            setOld(old)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">Mein Alter:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          type="number"
          value={old}
          onChange={(e) => {
            const dateSplitted = born.split("-")
            setOld(e.target.value)
            const now = new Date()
            const year = now.getFullYear() - e.target.value
            setBorn(`${year}-${dateSplitted[1]}-${dateSplitted[2]}`)
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </>
  )
}

export default Step2
