import React from "react"
import { createSvgIcon } from "@material-ui/core/utils"

export default createSvgIcon(
  <g>
    <path d="M9.79838538,4 L6.15020183,7.84046519 L4.20161462,5.78917549 L3,7.05412256 L5.54939452,9.73788579 C5.71510516,9.9123304 5.93265349,10 6.15020183,10 C6.36775016,10 6.58529849,9.9123304 6.75100914,9.73788579 L11,5.26494707 L9.79838538,4 Z"></path>
    <path d="M6.5,14.7710405 C4.769375,13.1001575 1.625,9.4864652 1.625,6.6935722 C1.625,3.9249434 3.8114375,1.67339305 6.5,1.67339305 C9.1885625,1.67339305 11.375,3.9249434 11.375,6.6935722 C11.375,9.4864652 8.230625,13.1001575 6.5,14.7710405 M6.5,0 C2.9160625,0 0,3.00290383 0,6.6935722 C0,10.3842406 3.77325,14.5919874 6.5,17 C9.2243125,14.5936608 13,10.3842406 13,6.6935722 C13,3.00290383 10.0839375,0 6.5,0"></path>
  </g>,
  "MapPointCheck"
)
