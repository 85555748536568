import React from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Box, Button, Grid, Typography } from "@material-ui/core"
import PhoneIcon from "@material-ui/icons/Phone"
import ChatBubbleIcon from "@material-ui/icons/ChatBubble"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //     backgroundColor: theme.palette.background.paper,
    //     marginBottom: "80px"
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  // listItem: {
  //     marginTop: theme.spacing(1),
  //     marginBottom: theme.spacing(1)
  // }
  button: {
    height: "55px",
  },
  content: {
    padding: theme.spacing(1),
  },
}))

function Search() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Container maxWidth="sm" style={{ padding: 0, backgroundColor: "white" }}>
      <AppBar text={"Arztpraxis Frau Dr. Haller"} back={"/search/arztpraxis/allgemeinaerzte"} />
      <Box my={4}>
        <img alt="profile" src="/static/images/profile_head.png" width="100%" />

        <Grid container className={classes.content}>
          {/*<Grid item xs={12} style={{ marginBottom: "2rem" }}>*/}
          {/*  Wir sprechen folgende Sprachen:*/}
          {/*</Grid>*/}

          <Grid item xs={6} style={{ padding: "0.4rem" }}>
            <Button
              className={classes.button}
              variant="outlined"
              fullWidth
              startIcon={<PhoneIcon />}
              style={{ textTransform: "initial" }}
              onClick={(e) => {
                window.open("tel:+49123456789")
              }}
            >
              anrufen
            </Button>
          </Grid>
          <Grid item xs={6} style={{ padding: "0.4rem" }}>
            <Button
              className={classes.button}
              variant="outlined"
              style={{ textTransform: "initial" }}
              fullWidth
              startIcon={<ChatBubbleIcon />}
              onClick={(e) => {
                history.push("/search/chat")
              }}
            >
              Termin vereinbaren
            </Button>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "2rem" }}>
            Humboldstraße 25a
            <br />
            43817 Erfurt
          </Grid>

          {/*<Grid item xs={12} style={{ marginTop: "1rem" }}>*/}
          {/*  <AvatarGroup max={6} avatars={["/static/images/flags/100px/de.png", "/static/images/flags/100px/gb.png"]} />*/}
          {/*</Grid>*/}

          <Grid item xs={12} style={{ marginTop: "2rem" }}>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6628.210006182881!2d11.02899837250172!3d50.960567735832996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a472c34c1cd015%3A0xc253c81b0f017f0!2sHumboldtstra%C3%9Fe%2025%2C%2099096%20Erfurt!5e0!3m2!1sde!2sde!4v1593953086171"
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <Typography variant="body1">Unsere Praxis ist teilweise barrierefrei.</Typography>
            <Typography variant="caption">mehr erfahren</Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "2rem", marginBottom: "6rem" }}>
            <Typography variant="body1">
              Liebe Patientinnen und Patienten,
              <br />
              <br />
              herzlich willkommen auf dem Profil unserer Allgemeinarztpraxis im Herzen von Erfurt. Unsere Leisungen umfassen...
            </Typography>
            <Typography variant="caption">weiter lesen</Typography>
          </Grid>
        </Grid>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default Search
