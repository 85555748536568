import React from "react"
import Grid from "@material-ui/core/Grid"

function ChatUser({ children }) {
  return (
    <>
      <Grid item xs={1} />
      <Grid
        item
        xs={11}
        style={{
          border: "1px solid gray",
          borderRadius: "6px",
          padding: "0.75rem",
          marginBottom: "0.5rem",
          backgroundColor: "white",
        }}
      >
        {children}
      </Grid>
    </>
  )
}

export default ChatUser
