import React, { useState } from "react"
import { Grid, List, ListItem, ListItemText, ListItemAvatar, Divider, Avatar } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"

const languages = [
  {
    name: "deutsch",
    icon: "/static/images/flags/100px/de.png",
  },
  {
    name: "slovenščina",
    icon: "/static/images/flags/100px/si.png",
  },
  {
    name: "english",
    icon: "/static/images/flags/100px/gb.png",
  },

  {
    name: "français",
    icon: "/static/images/flags/100px/fr.png",
  },
  {
    name: "русский",
    icon: "/static/images/flags/100px/ru.png",
  },
]

function Step3() {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  return (
    <Grid item xs={12}>
      <Typography variant="body1" style={{ marginBottom: "1rem" }}>
        Meine Nationalität:
      </Typography>

      <List component="nav" style={{ border: "1px solid black", borderRadius: "4px", borderColor: "#4C7DA3" }}>
        {languages.map((item, index) => (
          <>
            <ListItem button selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)}>
              <ListItemAvatar>
                <Avatar src={item.icon} />
              </ListItemAvatar>
              <ListItemText primary={item.name} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Grid>
  )
}

export default Step3
