import React, { useEffect, useState } from "react"
import { Container, Box, Paper, Typography } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import ChatContainer from "../../components/ChatContainer"
import ChatAlma from "../../components/ChatAlma"
import CheckboxText from "../../components/CheckboxText"
import Divider from "@material-ui/core/Divider"
import Step1 from "../SearchChat/step1"
import Step2 from "../SearchChat/Step2"
import Step3 from "../SearchChat/Step3"
import Step4 from "../SearchChat/Step4"

function ChatDrHaller1() {
  const [q1, setQ1] = useState(false)
  const [q2, setQ2] = useState(false)
  const [q3, setQ3] = useState(false)
  const [q4, setQ4] = useState(false)

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [])

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar text="Arztpraxis Dr. Haller" back="/chat" />
      <Box my={4}>
        <ChatVerlauf />

        <Divider />
        <Typography variant="body1" style={{ textAlign: "center", fontSize: "small", marginTop: "1rem" }}>
          12.08.2020
        </Typography>

        <ChatContainer>
          <ChatAlma>
            <Typography variant="body1">
              Hallo Frau Nowak,
              <br />
              wie können wir Ihnen heute weiterhelfen?
            </Typography>
          </ChatAlma>
        </ChatContainer>

        <Paper style={{ marginTop: "3rem", paddingBottom: "3rem" }}>
          <Typography color="primary" style={{ margin: "1rem", paddingTop: "2rem", fontWeight: 400, fontStyle: "italic" }} variant="body1">
            Ich benötige:
          </Typography>

          <CheckboxText
            text="einen Termin wegen akuten Beschwerden"
            checked={q1}
            onClick={(e) => {
              setQ1(true)
              setQ2(false)
              setQ3(false)
              setQ4(false)
            }}
          />

          <CheckboxText
            text="einen Kontroll- oder Vorsorgetermin"
            checked={q2}
            onClick={(e) => {
              setQ1(false)
              setQ2(true)
              setQ3(false)
              setQ4(false)
            }}
          />

          <CheckboxText
            text="ein Folgerezept"
            checked={q3}
            onClick={(e) => {
              setQ1(false)
              setQ2(false)
              setQ3(true)
              setQ4(false)
            }}
          />

          <CheckboxText
            text="eine Auskunft über die nächste Notfallsprechstunde"
            checked={q4}
            onClick={(e) => {
              setQ1(false)
              setQ2(false)
              setQ3(false)
              setQ4(true)
            }}
          />
        </Paper>
      </Box>
      <NavBar active={2} />
    </Container>
  )
}

const ChatVerlauf = () => (
  <>
    <Step1 />
    <Step2 />
    <Step3 />
    <Step4 />
  </>
)

export default ChatDrHaller1
