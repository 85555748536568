import React from "react"
import { Typography, Radio, Box, Grid } from "@material-ui/core"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import Checkbox from "../icons/Checkbox"
import CheckboxChecked from "../icons/CheckboxChecked"

const defaultProps = {
  bgcolor: "background.paper",
  style: { width: "100%" },
}

function CheckboxText({ text, value, checked, onClick, onChange }) {
  return (
    <Box borderRadius="borderRadius" {...defaultProps}>
      <Grid container>
        <Grid item xs={2} style={{ paddingTop: "8px", paddingLeft: "16px" }}>
          <Radio
            style={{ float: "right" }}
            checked={checked}
            onClick={onClick}
            onChange={onChange}
            color="primary"
            value={value}
            name="radio-button-demo"
            checkedIcon={<CheckboxChecked style={{ fontSize: 24 }} />}
            icon={<Checkbox />}
          />
        </Grid>

        <Grid item xs={10} style={{ paddingRight: "0.75rem" }}>
          <Typography color="primary" style={{ paddingTop: "17px", fontStyle: "italic" }}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CheckboxText
