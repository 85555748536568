import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, Paper } from "@material-ui/core"
import SwipeableViews from "react-swipeable-views"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import Step6 from "./Step6"
import Base from "./Base"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"

function SignupSettings() {
  const history = useHistory()

  const [viewIndex, setViewIndex] = useState(0)

  const handleChangeViewIndex = (index) => {
    setViewIndex(index)
  }

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <SwipeableViews index={viewIndex} onChangeIndex={handleChangeViewIndex} disabled={true}>
        <Base
          disableBack={true}
          activeStep={0}
          onClickNext={(e) => {
            setViewIndex(1)
          }}
        >
          <Step1 />
        </Base>

        <Base
          activeStep={1}
          onClickBack={(e) => {
            setViewIndex(0)
          }}
          onClickNext={(e) => {
            setViewIndex(2)
          }}
        >
          <Step2 />
        </Base>

        <Base
          activeStep={2}
          onClickBack={(e) => {
            setViewIndex(1)
          }}
          onClickNext={(e) => {
            setViewIndex(3)
          }}
        >
          <Step3 />
        </Base>

        <Base
          activeStep={3}
          onClickBack={(e) => {
            setViewIndex(2)
          }}
          onClickNext={(e) => {
            setViewIndex(4)
          }}
        >
          <Step4 />
        </Base>

        <Base
          activeStep={4}
          onClickBack={(e) => {
            setViewIndex(3)
          }}
          onClickNext={(e) => {
            setViewIndex(5)
          }}
        >
          <Step5 />
        </Base>

        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "2rem",
            marginTop: "15vh",
            padding: "1rem",
            minHeight: "70vh",
          }}
        >
          <Grid container spacing={3}>
            <Step6 />
          </Grid>
          <div style={{ marginTop: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ display: "flex", flexDirection: "column", marginTop: "0.3rem" }}>
                <KeyboardArrowLeft
                  style={{ marginLeft: "2rem" }}
                  onClick={(e) => {
                    setViewIndex(4)
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ textTransform: "initial" }}
                  onClick={(e) => {
                    history.push("/ask")
                  }}
                >
                  Fertig!
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </SwipeableViews>
    </Container>
  )
}

export default SignupSettings
