import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"
import ChatContainer from "../../components/ChatContainer"
import ChatAlma from "../../components/ChatAlma"

const Step1 = () => (
  <>
    <Typography variant="body1" style={{ textAlign: "center", fontSize: "0.85rem" }}>
      16.06.2020
      <br />
      Beginn des Chatverlaufs mit Arztpraxis Dr. Haller
    </Typography>

    <ChatContainer>
      <ChatAlma>
        Hallo,
        <br />
        hier können Sie mit unserer Praxis direkt einen Termin für sich oder eine andere Person vereinbaren. Bitte wählen Sie dafür einen
        von uns vorgeschlagenen Termin aus.
        <br />
        <br />
        Wenn es dringend ist und sofort Hilfe benötigt wird, wählen Sie bitte die Option „nächste Notfallsprechstunde anzeigen“ aus. Sie
        erhalten sofort eine Nachricht, wann unsere nächste Notfallsprechstunde stattfindet.
      </ChatAlma>
    </ChatContainer>
  </>
)

export default Step1
