import React from "react"
import { createSvgIcon } from "@material-ui/core/utils"

export default createSvgIcon(
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Artboard-Copy" transform="translate(0.000000, 0.000000)" fill="#4C7DA3" fillRule="nonzero">
      <g id="female" transform="translate(0.000000, 0.000000)">
        <path
          d="M10,5.14648437 C10,2.30871289 7.757,0 5,0 C2.243,0 0,2.30871289 0,5.14648437 C0,7.81624805 1.98532258,10.0174492 4.51612903,10.2687969 L4.51612903,13.6796875 L2.74193548,13.6796875 C2.47470968,13.6796875 2.25806452,13.9026797 2.25806452,14.1777344 C2.25806452,14.4527891 2.47470968,14.6757812 2.74193548,14.6757812 L4.51612903,14.6757812 L4.51612903,16.5019531 C4.51612903,16.7770078 4.73277419,17 5,17 C5.26722581,17 5.48387097,16.7770078 5.48387097,16.5019531 L5.48387097,14.6757812 L7.25806452,14.6757812 C7.52529032,14.6757812 7.74193548,14.4527891 7.74193548,14.1777344 C7.74193548,13.9026797 7.52529032,13.6796875 7.25806452,13.6796875 L5.48387097,13.6796875 L5.48387097,10.2687969 C8.01467742,10.0174492 10,7.81624805 10,5.14648437 Z M0.967741935,5.14648438 C0.967741935,2.85795898 2.7766129,0.99609375 5,0.99609375 C7.2233871,0.99609375 9.03225806,2.85795898 9.03225806,5.14648438 C9.03225806,7.43500977 7.2233871,9.296875 5,9.296875 C2.7766129,9.296875 0.967741935,7.43500977 0.967741935,5.14648438 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </g>,
  "Woman"
)
