import React from "react"
import { useHistory } from "react-router-dom"
import { Container, Paper, Typography, Button } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import Grid from "@material-ui/core/Grid"

// Search zwei states: - intro - normal (nachdem der user das intro hatte)
function SearchIntro() {
  const history = useHistory()

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar />
      <Paper style={{ margin: "2rem", marginBottom: "110px", padding: "1rem" }}>
        <Grid container>
          <Grid item xs={12}>
            <img alt="alma" src="/logo512.png" width="30%" />
            <Typography variant="body2">
              Hier im Suchbereich hast du zwei Möglichkeiten:
              <br />
              <br />
              Wenn du genau weisst, was du suchst, kannst du Ärzte oder andere medizinische Einrichtungen in einer Liste finden und direkt
              kontaktieren.
              <br />
              <br />
              Brauchst du Hilfe bei der Auswahl? Dann lass uns gemeinsam suchen! Dafür stellen wir dir hier ein paar Fragen und finden nach
              deinen Antworten den richtigen Ansprechpartner für dich!
            </Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "2rem" }}
              fullWidth
              onClick={(e) => {
                history.push("/search")
              }}
            >
              Alles klar!
            </Button>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Paper>
      <NavBar active={1} />
    </Container>
  )
}

export default SearchIntro
