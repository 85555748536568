import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, Box, Typography, Button } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import Bauch from "./bauch.svg"
import BauchCheck from "./bauch-check.svg"

function SearchGuideForMe() {
  const history = useHistory()

  const [click, setClick] = useState(false)
  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar back="/search/guide/me/3" text="Einrichtungssuche" />
      <Box my={4} style={{ margin: "2rem", marginBottom: "20px", padding: "1rem" }}>
        <Typography variant="body1" style={{ fontWeight: 400, marginBottom: "10vh" }}>
          In welcher Körperregion, oder in welchen Körperregionen hast du Beschwerden?
        </Typography>
      </Box>

      {click === false ? (
        <img
          src={Bauch}
          alt="React Logo"
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
          onClick={(e) => {
            setClick(true)
          }}
        />
      ) : (
        <img src={BauchCheck} alt="React Logo" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
      )}

      <Box my={4} style={{ margin: "2rem", marginBottom: "100px", padding: "1rem" }}>
        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "0.75rem",
            color: "#4C7DA3",
          }}
          onClick={(e) => {
            history.push("/search/guide/me/5")
          }}
        >
          Weiter
        </Button>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default SearchGuideForMe
