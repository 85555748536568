import React from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Typography,
  IconButton,
  List,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import SearchInput from "../../components/SearchInput"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  body1bold: {
    fontWeight: 400,
  },
}))

const themenListeMe = [
  {
    text: "Wie bin ich als nicht deutscher Student krankenversichert?",
    iconSrc: "/icons/pngs/icon_versicherung_200h.png",
    link: "/info/versicherung",
  },
  {
    text: "Wann gehe ich in Deutschland zu einem Arzt und wann ins Krankenhaus?",
    iconSrc: "/icons/pngs/icon_arzt_krankenhaus_200h.png",
    link: "/info/versicherung",
  },
  {
    text: "Was muss ich in Deutschland beim Arzt beachten?",
    iconSrc: "/icons/pngs/icon_arzt_frage_200h.png",
    link: "/info/versicherung",
  },
  {
    text: "Mein Deutsch ist noch nicht so gut. Wer hilft mir beim Arzt mit der Sprache?",
    iconSrc: "/icons/pngs/icon_patient_frage_200h.png",
    link: "/info/versicherung",
  },
  {
    text: "An wen wende ich mich in einem medizinischen Notfall?",
    iconSrc: "/icons/pngs/icon_krankenwagen_200h.png",
    link: "/info/versicherung",
  },
]

const themenListeAllgemein = [
  {
    text: "Was ist eine Krankenversicherung?",
    iconSrc: "/icons/pngs/icon_versicherung_200h.png",
    link: "/info/versicherung",
  },
  {
    text: "Warum sollte ich krankenversichert sein?",
    iconSrc: "/icons/pngs/icon_versicherung_200h.png",
    link: "/info/versicherung",
  },
]

function Info() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar />

      <Grid container style={{ padding: "1rem" }}>
        <Grid item xs={12}>
          <SearchInput placeholder="z.B. Krankenversicherung, Dolmetscher" />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Grid item xs={2} />
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.body1bold}>
            Interessante Themen für dich:
          </Typography>
        </Grid>
      </Grid>

      <List dense className={classes.root}>
        {themenListeMe.map((value, index) => {
          return (
            <ListItem
              key={index}
              button
              onClick={(e) => {
                history.push(value.link)
              }}
            >
              <ListItemAvatar>
                <Avatar src={value.iconSrc} />
              </ListItemAvatar>
              <ListItemText primary={value.text} style={{ color: "#4C7DA3" }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <NavigateNextIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>

      <Grid container style={{ marginTop: "2rem", marginBottom: "1rem" }}>
        <Grid item xs={2} />
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.body1bold}>
            Alle Themen:
          </Typography>
        </Grid>
      </Grid>

      <List dense className={classes.root} style={{ marginBottom: "80px", paddingBottom: "160px" }}>
        {themenListeAllgemein.map((value, index) => {
          return (
            <ListItem
              key={index}
              button
              onClick={(e) => {
                history.push(value.link)
              }}
            >
              <ListItemAvatar>
                <Avatar src={value.iconSrc} />
              </ListItemAvatar>
              <ListItemText primary={value.text} style={{ color: "#4C7DA3" }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <NavigateNextIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>

      <NavBar active={3} />
    </Container>
  )
}

export default Info
