import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import IconTextCheckbox from "../../components/IconTextCheckbox"
import IconVersicherungDE from "../../icons/VersicherungDE"
import IconVersicherungEU from "../../icons/VersicherungEU"
import IconVersicherungOther from "../../icons/VersicherungOther"
import Typography from "@material-ui/core/Typography"

function Step6() {
  const [selectedValue, setSelectedValue] = useState("")

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  return (
    <Grid item xs={12}>
      <Typography variant="body1" style={{ marginTop: "3rem", marginBottom: "1rem" }}>
        Ich bin versichert in:
      </Typography>

      <IconTextCheckbox
        icon={<IconVersicherungDE color="primary" fontSize={"medium"} />}
        text="einer deutschen Krankenversicherung"
        value="a"
        onChange={handleChange}
        checked={selectedValue === "a"}
      />

      <IconTextCheckbox
        icon={<IconVersicherungEU color="primary" fontSize={"medium"} />}
        text="der Krankenversicherung eines EU-Mitgliedsstaats"
        value="b"
        onChange={handleChange}
        checked={selectedValue === "b"}
      />

      <IconTextCheckbox
        icon={<IconVersicherungOther color="primary" fontSize={"medium"} />}
        text="nicht in Deutschland oder der EU krankenversichert"
        value="c"
        onChange={handleChange}
        checked={selectedValue === "c"}
      />
    </Grid>
  )
}

export default Step6
