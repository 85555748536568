import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import IconTextCheckbox from "../../components/IconTextCheckbox"
import StudentIcon from "../../icons/Student"
import IconAlterMann from "../../icons/AlterMann"
import IconFrau from "../../icons/Frau"
import IconArbeitsuchend from "../../icons/Arbeitsuchend"
import IconArbeiter from "../../icons/Arbeiter"
import Typography from "@material-ui/core/Typography"

function Step5() {
  const [selectedValue, setSelectedValue] = useState("")

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  return (
    <Grid item xs={12}>
      <Typography variant="body1" style={{ marginBottom: "1rem" }}>
        Derzeit bin ich:
      </Typography>

      <IconTextCheckbox
        text="Schülerin / Studentin / Auszubildende"
        icon={<StudentIcon color="primary" />}
        value="a"
        onChange={handleChange}
        checked={selectedValue === "a"}
      />
      <IconTextCheckbox
        text="Arbeitnehmerin"
        icon={<IconArbeiter color="primary" />}
        value="b"
        onChange={handleChange}
        checked={selectedValue === "b"}
      />
      <IconTextCheckbox
        text="Arbeitsuchende"
        icon={<IconArbeitsuchend color="primary" />}
        value="c"
        onChange={handleChange}
        checked={selectedValue === "c"}
      />
      <IconTextCheckbox
        text="Rentnerin"
        icon={<IconAlterMann color="primary" />}
        value="d"
        onChange={handleChange}
        checked={selectedValue === "d"}
      />
      <IconTextCheckbox
        text="anderer Status"
        icon={<IconFrau color="primary" />}
        value="e"
        onChange={handleChange}
        checked={selectedValue === "e"}
      />
    </Grid>
  )
}

export default Step5
