import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <g id="chat-icon" transform="translate(0, 2)">
        <path
          d="M11.6129032,15 L11.6129032,12 L10.0645161,12 C9.21058065,12 8.51612903,11.32725 8.51612903,10.5 L8.51612903,1.5 C8.51612903,0.67275 9.21058065,0 10.0645161,0 L22.4516129,0 C23.3055484,0 24,0.67275 24,1.5 L24,10.5 C24,11.32725 23.3055484,12 22.4516129,12 L15.7416774,12 L11.6129032,15 Z"
          fill="#605E61"
          transform="translate(16.258065, 7.500000) scale(-1, 1) translate(-16.258065, -7.500000) "
        ></path>
        <path
          d="M3.09677419,18 L3.09677419,15 L1.5483871,15 C0.694451613,15 0,14.32725 0,13.5 L0,4.5 C0,3.67275 0.694451613,3 1.5483871,3 L13.9354839,3 C14.7894194,3 15.483871,3.67275 15.483871,4.5 L15.483871,13.5 C15.483871,14.32725 14.7894194,15 13.9354839,15 L7.22554839,15 L3.09677419,18 Z"
          fill="#605E61"
        ></path>
        <polygon
          fill="#FFFFFF"
          points="1.5483871 4.5 1.5483871 13.5 4.64516129 13.5 4.64516129 15 6.70993548 13.5 13.9354839 13.5 13.9354839 4.5"
        ></polygon>
      </g>
    </SvgIcon>
  )
}

export default Icon
