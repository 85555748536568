import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, Box, Typography, Button } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import CheckboxText from "../../components/CheckboxText"

function SearchGuideForMe() {
  const history = useHistory()
  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)
  const [check4, setCheck4] = useState(false)
  const [check5, setCheck5] = useState(false)

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar back="/search/guide/me/4" text="Einrichtungssuche" />
      <Box my={4} style={{ margin: "1rem", padding: "1rem" }}>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          Welche der folgenden Symptome kannst du feststellen?
        </Typography>
      </Box>

      <CheckboxText
        text={"Blähungen"}
        checked={check1}
        onClick={(e) => {
          setCheck1(!check1)
        }}
      />
      <CheckboxText
        text={"Druckempfindlichkeit"}
        checked={check2}
        onClick={(e) => {
          setCheck2(!check2)
        }}
      />
      <CheckboxText
        text={"Erbrechen"}
        checked={check3}
        onClick={(e) => {
          setCheck3(!check3)
        }}
      />
      <CheckboxText
        text={"Krämpfe"}
        checked={check4}
        onClick={(e) => {
          setCheck4(!check4)
        }}
      />
      <CheckboxText
        text={"Sodbrenen"}
        checked={check5}
        onClick={(e) => {
          setCheck5(!check5)
        }}
      />

      <Box my={4} style={{ margin: "2rem", marginBottom: "100px", padding: "1rem" }}>
        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "0.75rem",
            color: "#4C7DA3",
          }}
          onClick={(e) => {
            history.push("/search/guide/me/5b")
          }}
        >
          Weiter
        </Button>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default SearchGuideForMe
