import React from "react"
import { useHistory } from "react-router-dom"
import {
  Container,
  Box,
  Paper,
  List,
  Divider,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core"
import AvatarGroup from "../../components/AvatarGroup"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"

const chats = [
  {
    avatars: ["/static/images/chat/person_1.png", "/icons/pngs/Allgemeinarzt_200h.png"],
    name: "Allgemeinärztin Frau Dr. Haller",
    subline: "Deine Daten wurden erfolgreich …",
    date: "12.08.2020",
  },
  {
    avatars: ["/static/images/chat/person_2.png", "/icons/pngs/Anaesthesist_200h.png"],
    name: "Frauenärztin Frau Dr. Steyer",
    subline: "Bitte bringen Sie zum nächsten …",
    date: "01.07.2020",
  },
  {
    avatars: ["/static/images/chat/person_3.png", "/icons/pngs/icon_Augenarzt.png"],
    name: "Augenarzt Herr Dr. Friedrich",
    subline: "Bis zum nächsten Termin im …",
    date: "03.08.2020",
  },
]

function Chat() {
  const history = useHistory()

  const handleClick = (v) => {
    if (v === 0) {
      history.push("/chat/dr-haller-1")
    }
  }
  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar />
      <Box>
        <Paper>
          <List dense={true}>
            {chats.map((chat, index) => (
              <>
                <ListItem
                  style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }}
                  button
                  onClick={(e) => {
                    handleClick(index)
                  }}
                >
                  <ListItemAvatar style={{ marginRight: "0.5rem" }}>
                    <AvatarGroup max={2} avatars={chat.avatars} />
                  </ListItemAvatar>
                  <ListItemText primary={chat.name} secondary={chat.subline} />
                  <ListItemSecondaryAction style={{ marginTop: "-10px" }}>
                    <Typography variant="caption">{chat.date}</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Paper>
      </Box>
      <NavBar active={2} />
    </Container>
  )
}

export default Chat
