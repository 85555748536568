import React from "react"
import { Button, Grid } from "@material-ui/core"
// import almaLogo from "../../logo/logo_with_text.png"
import Typography from "@material-ui/core/Typography"

function Step5({ onClickNow, onClickLater }) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/*<img alt="alma" src={almaLogo} width="50%" />*/}
          <Typography variant="body1">
            Im nächsten Schritt benötigen wir einige Angaben für dein persönliches Profil, um dir für dich interessante Informationen
            anzeigen zu können. Du kannst sie auch später in deinem persönlichen Profil einstellen und bearbeiten.
            <br />
            Wenn du Alma nutzen möchtest, um Kontakt zu einer medizinischen Einrichtung aufzunehmen, können diese Daten dabei Hilfreich
            sein, z.B. um einen englischsprachigen Arzt zu finden. Ausserdem können auf deinen Wunsch einige Angaben an die Einrichtung
            übermittelt werden, wenn du Kontakt aufnimmst.
          </Typography>
        </Grid>
      </Grid>

      <div style={{ marginTop: "auto" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button fullWidth style={{ marginBottom: "1.5rem" }} color="primary" variant="contained" onClick={onClickNow}>
              Jetzt
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button fullWidth style={{ marginBottom: "1.5rem" }} variant="outlined" onClick={onClickLater}>
              Später
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Step5
