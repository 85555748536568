import React from "react"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import Avatar from "@material-ui/core/Avatar"

function AGroup({ max = 2, avatars }) {
  return (
    <AvatarGroup max={max} spacing={"small"}>
      {avatars.map((avatar) => (
        <Avatar src={avatar} style={{ border: "2px solid #fff" }} />
      ))}
    </AvatarGroup>
  )
}

export default AGroup
