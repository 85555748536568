import React, { useState } from "react"
import { Button, Grid, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar } from "@material-ui/core"
// import almaLogo from "../../logo/logo_with_text.png"
import Typography from "@material-ui/core/Typography"

// const defaultProps = {
//   bgcolor: "background.paper",
//   borderColor: "text.primary",
//   m: 1,
//   border: 1,
//   style: { width: "100%" },
// }

const languages = [
  {
    name: "Slovenščina",
    icon: "/static/images/flags/100px/si.png",
  },
  {
    name: "English",
    icon: "/static/images/flags/100px/gb.png",
  },
  {
    name: "Deutsch",
    icon: "/static/images/flags/100px/de.png",
  },
  {
    name: "Français",
    icon: "/static/images/flags/100px/fr.png",
  },
  {
    name: "Pусский",
    icon: "/static/images/flags/100px/ru.png",
  },
]

function Step3({ onClick }) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/*<img alt="alma" src={almaLogo} width="50%"/>*/}
          <Typography variant="body1">
            Willkommen!
            <br />
            Bitte wähle zuerst die Sprache aus, in der du die App nutzen möchtest.
          </Typography>
        </Grid>

        {/*<Box borderRadius="borderRadius" {...defaultProps}>*/}
        <Grid item xs={12}>
          <List component="nav" style={{ maxHeight: 246, overflow: "auto", border: "1px solid #4C7DA3", borderRadius: "6px" }}>
            {languages.map((item, index) => (
              <>
                <ListItem button selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)}>
                  <ListItemAvatar>
                    <Avatar src={item.icon} color="primary" />
                  </ListItemAvatar>
                  <ListItemText primary={item.name} color="primary" />
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Grid>
        {/*</Box>*/}
      </Grid>

      <div style={{ marginTop: "auto" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <Button variant="contained" color="primary" style={{ marginBottom: "1.5rem", textTransform: "initial" }} onClick={onClick}>
              Weiter
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Step3
