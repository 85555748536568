import React from "react"
import { useHistory } from "react-router-dom"
import { Button, MobileStepper, Grid, Paper, IconButton } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    margin: "2rem",
    marginTop: "15vh",
    padding: "1rem",
    // paddingTop: "0.25rem",
    minHeight: "70vh",
  },
}))

function Base({ activeStep, disableBack = false, disableNext = false, onClickBack, onClickNext, children }) {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <span style={{ float: "right" }}>
            <IconButton
              aria-label="delete"
              size="medium"
              style={{ margin: "0", padding: "0" }}
              onClick={(e) => {
                history.push("/ask")
              }}
            >
              <HighlightOffIcon fontSize="inherit" color="primary" />
            </IconButton>
          </span>
        </Grid>

        {children}
      </Grid>

      <div style={{ marginTop: "auto" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <MobileStepper
              style={{ backgroundColor: "white", width: "100%" }}
              variant="dots"
              steps={6}
              position="static"
              activeStep={activeStep}
              backButton={
                <Button size="small" disabled={disableBack} onClick={onClickBack} style={{ textTransform: "initial" }}>
                  {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  {/*Zurück*/}
                </Button>
              }
              nextButton={
                <Button size="small" disabled={disableNext} onClick={onClickNext} style={{ textTransform: "initial" }}>
                  {/*Weiter*/}
                  {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}

export default Base
