import React from "react"
import { Container, Typography, Paper, Grid } from "@material-ui/core"
import NavBar from "../../components/NavBar"

function Settings() {
  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <Paper style={{ margin: "2rem", marginTop: "6rem", padding: "1rem" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1">Hier im Suchbereich hast du zwei Möglichkeiten:</Typography>

            <Typography variant="body1">
              Wenn du genau weisst, was du suchst, kannst du Ärzte oder andere medizinische Einrichtungen in einer Liste finden und direkt
              kontaktieren.
            </Typography>

            <Typography variant="body1">
              Brauchst du Hilfe bei der Auswahl? Dann lass uns gemeinsam suchen! Dafür stellen wir dir hier ein paar Fragen und finden nach
              deinen Antworten den richtigen Ansprechpartner für dich!
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <NavBar />
    </Container>
  )
}

export default Settings
