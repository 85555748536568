import React from "react"
import { useHistory } from "react-router-dom"
import { Container, Box, Typography, Button } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"

function SearchGuideForMe() {
  const history = useHistory()

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar back="/search/guide/me/6" text="Einrichtungssuche" />
      <Box my={4} style={{ margin: "2rem", marginBottom: "20px", padding: "1rem" }}>
        <Typography variant="body1" style={{ fontWeight: 400, marginBottom: "1rem" }}>
          Aufgrund deiner Angaben empfehlen wir dir, zuerst einen <b>Allgemeinarzt</b> aufzusuchen. Sollen wir dir helfen, einen{" "}
          <b>allgemeinmedizinische Praxis</b> in deiner Nähe zu finden?
        </Typography>

        <Typography variant="caption" style={{ marginBottom: "10vh" }}>
          Du willst mehr darüber wissen, was ein Allgemeinarzt oder eine allgemein-medizinische Praxis ist? Dann tippe <a href="">hier!</a>
        </Typography>
      </Box>

      <Box my={4} style={{ margin: "2rem", marginBottom: "100px", padding: "1rem" }}>
        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "0.75rem",
            color: "#4C7DA3",
          }}
          onClick={(e) => {
            history.push("/search/arztpraxis/allgemeinaerzte")
          }}
        >
          Ja, zeig mir Allgemeinärzte in der Nähe
        </Button>

        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "0.75rem",
            color: "#4C7DA3",
          }}
          onClick={(e) => {
            history.push("/search")
          }}
        >
          Nein, Danke
        </Button>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default SearchGuideForMe
