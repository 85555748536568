import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import SwipeableViews from "react-swipeable-views"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    margin: "2rem",
    marginTop: "10vh",
    padding: "1rem",
    minHeight: "80vh",
  },
}))

function Signup() {
  const classes = useStyles()
  const history = useHistory()
  const [viewIndex, setViewIndex] = useState(0)

  const handleChangeViewIndex = (index) => {
    setViewIndex(index)
  }

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <SwipeableViews index={viewIndex} onChangeIndex={handleChangeViewIndex} disabled={true}>
        <div>
          <Paper className={classes.paper}>
            <Step1
              onClick={(e) => {
                setViewIndex(1)
              }}
            />
          </Paper>
        </div>

        <div>
          <Paper className={classes.paper}>
            <Step2
              onClick={(e) => {
                setViewIndex(2)
              }}
            />
          </Paper>
        </div>

        <div>
          <Paper className={classes.paper}>
            <Step3
              onClick={(e) => {
                setViewIndex(3)
              }}
            />
          </Paper>
        </div>

        <div>
          <Paper className={classes.paper}>
            <Step4
              onClick={(e) => {
                setViewIndex(4)
              }}
            />
          </Paper>
        </div>

        <div>
          <Paper className={classes.paper}>
            <Step5
              onClickNow={(e) => {
                history.push("/signup/profile")
              }}
              onClickLater={(e) => {
                history.push("/ask")
              }}
            />
          </Paper>
        </div>
      </SwipeableViews>
    </Container>
  )
}

export default Signup
