import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, Box, Typography, Button } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
// import IconTextCheckbox from "../../components/IconTextCheckbox"
// import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import CheckboxText from "../../components/CheckboxText"

function SearchGuideForMe() {
  const history = useHistory()
  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)
  const [check4, setCheck4] = useState(false)
  const [check5, setCheck5] = useState(false)

  const handleChange = (e) => {
    history.push("/search/guide/me")
  }

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar back="/search/guide" text="Einrichtungssuche" />
      <Box my={4} style={{ margin: "2rem", marginBottom: "10px", padding: "1rem" }}>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          Treffen von den folgenden Aussagen eine oder mehrere auf dich zu?
        </Typography>
      </Box>

      <CheckboxText
        text="Ich leide unter einer Vorerkrankung / chronischen Erkrankung."
        checked={check1}
        onClick={() => {
          setCheck1(!check1)
          setCheck5(false)
        }}
      />
      <CheckboxText
        text="Ich habe eine körperliche  und / oder geistige Einschränkung."
        checked={check2}
        onClick={() => {
          setCheck2(!check2)
          setCheck5(false)
        }}
      />
      <CheckboxText
        text="Ich hatte kürzlich eine Verletzung und / oder eine Operation."
        checked={check3}
        onClick={() => {
          setCheck3(!check3)
          setCheck5(false)
        }}
      />
      <CheckboxText
        text="Ich bin schwanger."
        checked={check4}
        onClick={() => {
          setCheck4(!check4)
          setCheck5(false)
        }}
      />
      <CheckboxText
        text="Keine Aussage trifft zu."
        checked={check5}
        onClick={() => {
          setCheck1(false)
          setCheck2(false)
          setCheck3(false)
          setCheck4(false)
          setCheck5(!check5)
        }}
      />

      <Box my={4} style={{ margin: "2rem", marginBottom: "120px", padding: "1rem" }}>
        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "0.75rem",
            color: "#4C7DA3",
          }}
          onClick={(e) => {
            history.push("/search/guide/me")
          }}
        >
          Weiter
        </Button>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default SearchGuideForMe
