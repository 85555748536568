import React, { useState } from "react"
import { Grid, List, ListItem, ListItemText, ListItemAvatar, Divider, Avatar } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const languages = [
  {
    name: "deutsch",
    icon: "/static/images/flags/100px/de.png",
  },
  {
    name: "Slovenščina",
    icon: "/static/images/flags/100px/si.png",
  },
  {
    name: "english",
    icon: "/static/images/flags/100px/gb.png",
  },
  {
    name: "français",
    icon: "/static/images/flags/100px/fr.png",
  },
  {
    name: "русский",
    icon: "/static/images/flags/100px/ru.png",
  },
]

function Step4() {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  return (
    <Grid item xs={12}>
      <Typography variant="body1" style={{ marginBottom: "0.5rem" }}>
        Sprachen die ich spreche:
      </Typography>
      <List
        component="nav"
        style={{
          border: "1px solid black",
          borderRadius: "4px",
          borderColor: "#4C7DA3",
          maxHeight: "100px",
          overflowY: "auto",
        }}
      >
        <ListItem button selected={false}>
          <ListItemAvatar>
            <Avatar src={languages[1].icon} />
          </ListItemAvatar>
          <ListItemText primary={languages[1].name} />

          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete">
              <CloseIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Typography variant="body1" style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
        Weitere Sprachen:
      </Typography>
      <List
        component="nav"
        style={{
          border: "1px solid black",
          borderRadius: "4px",
          borderColor: "#4C7DA3",
          maxHeight: "30vh",
          overflowY: "auto",
        }}
      >
        {languages.map((item, index) => (
          <>
            <ListItem button selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index)}>
              <ListItemAvatar>
                <Avatar src={item.icon} />
              </ListItemAvatar>
              <ListItemText primary={item.name} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Grid>
  )
}

export default Step4
