import React from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Avatar,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}))

const liste = [
  {
    text: "Arztpraxis Frau Dr. Haller",
    secondary: "Entfernung 0,8 km",
    avatars: ["/icons/pngs/Allgemeinarzt_200h.png"],
  },
  {
    text: "Gemeinschaftspraxis Frau Dr. Lieber / Herr Dr. Scholz",
    secondary: "Entfernung 1,0 km",
    avatars: ["/icons/pngs/Allgemeinarzt_200h.png", "/icons/pngs/Anaesthesist_200h.png"],
  },

  {
    text: "Gemeinschaftspraxis Frau Dr. Stefan / Herr Dr. Müller",
    secondary: "Entfernung 0,5 km",
    avatars: ["/icons/pngs/Allgemeinarzt_200h.png"],
  },
  {
    text: "Arztpraxis Herr Dr. Brandtstätter",
    secondary: "Entfernung 0,7 km",
    avatars: ["/icons/pngs/Allgemeinarzt_200h.png"],
  },
  {
    text: "Arztpraxis Frau Dr. Großer",
    secondary: "Entfernung 1,2 km",
    avatars: ["/icons/pngs/Allgemeinarzt_200h.png"],
  },
  {
    text: "Gemeinschaftspraxis Herr Dr. Buchholz / Herr Dr. Hochfeld",
    secondary: "Entfernung 1,9 km",
    avatars: ["/icons/pngs/Allgemeinarzt_200h.png"],
  },
]

function SearchGuideForMe() {
  const classes = useStyles()
  const history = useHistory()

  const handleToggle = (e) => {
    history.push("/search/profile")
  }

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar back="/search/guide/me/7" text="Einrichtungssuche" />

      <Grid container style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Grid item xs={2} />
        <Grid item xs={10}>
          <Typography variant="body1" style={{ fontWeight: 400, marginTop: "2rem" }}>
            Wir haben 8 Allgemeinarztpraxen im Umkreis von 1,5 km gefunden, davon sprechen 2 Praxen englisch:
          </Typography>
        </Grid>
      </Grid>

      <List dense className={classes.root} style={{ marginBottom: "80px" }}>
        {liste.map((value, index) => {
          return (
            <ListItem
              key={index}
              button
              onClick={(e) => {
                handleToggle(index)
              }}
            >
              <ListItemAvatar style={{ marginRight: "0.5rem" }}>
                <AvatarGroup max={2} spacing={20}>
                  {value.avatars.map((avatar) => (
                    <Avatar src={avatar} style={{ border: "2px solid #fff" }} />
                  ))}
                </AvatarGroup>
              </ListItemAvatar>
              <ListItemText primary={value.text} secondary={value.secondary} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments">
                  <NavigateNextIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>

      <NavBar active={1} />
    </Container>
  )
}

export default SearchGuideForMe
