import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(1.000000, -2.000000)" fill="#605E61">
          <g id="information-icon" transform="translate(2.000000, 2.000000)">
            <g>
              <path
                d="M10,2.0005 C5.589,2.0005 2,5.5895 2,10.0005 C2,14.4115 5.589,18.0005 10,18.0005 C14.411,18.0005 18,14.4115 18,10.0005 C18,5.5895 14.411,2.0005 10,2.0005 M10,20.0005 C4.486,20.0005 0,15.5155 0,10.0005 C0,4.4865 4.486,0.0005 10,0.0005 C15.514,0.0005 20,4.4865 20,10.0005 C20,15.5155 15.514,20.0005 10,20.0005"
                id="Fill-438"
              ></path>
              <polygon
                id="Fill-439"
                transform="translate(10.000000, 11.500000) scale(-1, -1) rotate(90.000000) translate(-10.000000, -11.500000) "
                points="13.5 12.5 6.5 12.5 6.5 10.5 13.5 10.5"
              ></polygon>
              <polygon
                id="Fill-439"
                transform="translate(10.000000, 8.000000) scale(-1, -1) translate(-10.000000, -8.000000) "
                points="12 9 8 9 8 7 12 7"
              ></polygon>
              <polygon
                id="Fill-439"
                transform="translate(10.000000, 5.000000) scale(-1, -1) translate(-10.000000, -5.000000) "
                points="11 6 9 6 9 4 11 4"
              ></polygon>
              <polygon
                id="Fill-439"
                transform="translate(10.000000, 15.000000) scale(-1, -1) translate(-10.000000, -15.000000) "
                points="12 16 8 16 8 14 12 14"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default Icon
