import React from "react"
import { Typography, Radio, Box, Grid } from "@material-ui/core"
import Checkbox from "../icons/Checkbox"
import CheckboxChecked from "../icons/CheckboxChecked"

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "#4C7DA3",
  border: 1,
  marginBottom: 1,
  style: { width: "99%" },
}

function IconTextCheckbox({ icon, text, value, checked, onClick, onChange }) {
  return (
    <Box borderRadius="borderRadius" {...defaultProps}>
      <Grid container>
        <Grid item xs={2} style={{ paddingTop: "12px", paddingLeft: "16px" }}>
          {icon}
        </Grid>

        <Grid item xs={8}>
          <Typography style={{ paddingTop: "10px", fontStyle: "italic" }} color="primary">
            {text}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Radio
            style={{ float: "right" }}
            checked={checked}
            onClick={onClick}
            onChange={onChange}
            color="primary"
            value={value}
            name="radio-button-demo"
            checkedIcon={<CheckboxChecked style={{ fontSize: 24 }} />}
            icon={<Checkbox />}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default IconTextCheckbox
