import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import Man from "../../icons/Man"
import Woman from "../../icons/Woman"
import Diverse from "../../icons/Diverse"
import IconTextCheckbox from "../../components/IconTextCheckbox"
import Typography from "@material-ui/core/Typography"

function Step1({ onClick }) {
  const [selectedValue, setSelectedValue] = useState("")

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  return (
    <Grid item xs={12}>
      <Typography variant="body1" style={{ marginBottom: "1rem" }}>
        Sehr gut, dann lass uns gleich starten!
      </Typography>

      <Typography variant="body1" gutterBottom>
        Ich bin:
      </Typography>

      <IconTextCheckbox
        icon={<Woman color="primary" />}
        text={"weiblich"}
        value="a"
        onChange={handleChange}
        checked={selectedValue === "a"}
      />
      <IconTextCheckbox
        icon={<Man color="primary" />}
        text={"männlich"}
        value="b"
        onChange={handleChange}
        checked={selectedValue === "b"}
      />
      <IconTextCheckbox
        icon={<Diverse color="primary" />}
        text={"divers"}
        value="c"
        onChange={handleChange}
        checked={selectedValue === "c"}
      />
    </Grid>
  )
}

export default Step1
