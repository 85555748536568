import React, { useState } from "react"
import { Button, Grid } from "@material-ui/core"
import IconTextCheckbox from "../../components/IconTextCheckbox"
import IconMapPoint from "../../icons/MapPoint"
import IconMail from "../../icons/Mail"
import Typography from "@material-ui/core/Typography"

function Step4({ onClick }) {
  const [checkStandort, setCheckStandort] = useState(false)
  const [checkPush, setCheckPush] = useState(false)
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/*<img alt="alma" src={almaLogo} width="50%" />*/}
          <Typography variant="body1">
            Bitte erlaube uns auf deinen Standort zuzugreifen, um dir immer Einrichtungen in deiner Nähe zeigen zu können. Über
            Push-Mitteilungen kannst du Nachrichten, z.B. von medizinischen Einrichtungen, auch dann empfangen, wenn du die Alma-App nicht
            geöffnet hast. So verpasst du keine wichtigen Infos!
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "1rem" }}>
          <IconTextCheckbox
            text="Standortzugriff erlauben"
            icon={<IconMapPoint fontSize="medium" color="primary" />}
            value={"a"}
            checked={checkStandort}
            onClick={(e) => {
              setCheckStandort(!checkStandort)
            }}
          />
          <IconTextCheckbox
            text="Pushmitteilungen senden"
            icon={<IconMail fontSize="medium" color={"primary"} />}
            value={"a"}
            checked={checkPush}
            onClick={(e) => {
              setCheckPush(!checkPush)
            }}
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: "auto" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <Button variant="contained" color="primary" style={{ marginBottom: "1.5rem", textTransform: "initial" }} onClick={onClick}>
              Weiter
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Step4
