import React from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Box,
  Typography,
  Divider,
  IconButton,
  List,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import SearchInput from "../../components/SearchInput"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "80px",
  },
  listItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const searchList = [
  {
    text: "Allgemeinärzte (8)",
    iconSrc: "/icons/pngs/Allgemeinarzt_200h.png",
  },
  {
    text: "Augenärzte (8)",
    iconSrc: "/icons/pngs/icon_Augenarzt.png",
  },
  {
    text: "Anästhesisten (1)",
    iconSrc: "/icons/pngs/Anaesthesist_200h.png",
  },

  {
    text: "Chirurgen (0)",
    iconSrc: "/icons/pngs/chirurg_200h.png",
  },
  {
    text: "Ärzte für Frauenheilkunde und Geburtshilfe (Gynäkologen) (2)",
    iconSrc: "/icons/pngs/Allgemeinarzt_200h.png",
  },
  {
    text: "Zahnarzt (1)",
    iconSrc: "/icons/pngs/zahnarzt_200h.png",
  },
  {
    text: "Kardiologen (1)",
    iconSrc: "/icons/pngs/icon_kardiologe_200h.png",
  },
  {
    text: "Kardiologen (1)",
    iconSrc: "/icons/pngs/icon_kardiologe_200h.png",
  },
]

function Search() {
  const classes = useStyles()
  const history = useHistory()

  const handleToggle = (e) => {
    if (e === 0) {
      history.push("/search/arztpraxis/allgemeinaerzte")
    }
  }

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar text={"Arztpraxis"} back={"/search"} />
      <Box my={4}>
        <Grid container style={{ marginBottom: "1em" }}>
          <Grid item xs={12} style={{ padding: "1rem" }}>
            <SearchInput placeholder={"z.B. Kinderarzt oder Dr. Müller"} />
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant="body2">Im Umkreis von 1,5 km von deinem Standort befinden sich folgende Arztpraxen:</Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>

        <List dense className={classes.root}>
          {searchList.map((value, index) => {
            return (
              <>
                <ListItem
                  key={index}
                  button
                  onClick={(e) => {
                    handleToggle(index)
                  }}
                  className={classes.listItem}
                >
                  <ListItemAvatar>
                    <Avatar src={value.iconSrc} />
                  </ListItemAvatar>
                  <ListItemText primary={value.text} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      <NavigateNextIcon color="primary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            )
          })}
        </List>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default Search
