import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"

const Step2 = () => (
  <div style={{ backgroundColor: "#ECF1F5" }}>
    <Typography variant="body1" style={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "0.5rem" }}>
      Deine Terminanfrage für den 17.06.2020 um 8.30 Uhr wurden mit deinem Namen an die Praxis von Frau Dr. Haller übermittelt.
    </Typography>
    <p style={{ textAlign: "center", paddingBottom: "0.5rem" }}>
      <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Artboard">
            <circle id="Oval" stroke="#605E61" fillRule="nonzero" cx="16" cy="16" r="15"></circle>
            <g id="checkmark" transform="translate(5.000000, 9.000000)" fill="#605E61">
              <polygon
                id="Fill-95"
                points="7.88454454 14.1925586 -1.52944324e-12 6.82765625 1.20604602 5.65015625 7.88454454 11.8358919 21.0334395 3.55271368e-15 22.2394855 1.1775"
              ></polygon>
            </g>
          </g>
        </g>
      </svg>
    </p>
  </div>
)

export default Step2
