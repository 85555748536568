import React, { useState } from "react"
import { Button, Grid } from "@material-ui/core"
import ReactCodeInput from "react-verification-code-input"
// import almaLogo from "../../logo/logo_with_text.png"
import Typography from "@material-ui/core/Typography"

function Step2({ onClick }) {
  const [showAnmeldenButton, setShowAnmeldenButton] = useState(false)

  return (
    <>
      <Grid item xs={12}>
        {/*<img alt="alma" src={almaLogo} width="50%" />*/}
        <Typography variant="body1">
          Bitte setze hier den Verifizierungscode ein, den du von uns per SMS an die Telefonnummer endend mit{" "}
          <span style={{ fontWeight: "bold" }}>******6853</span> erhalten hast.
        </Typography>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} style={{ marginTop: "5rem", marginBottom: "3rem" }}>
          <ReactCodeInput
            className={"signup-code"}
            autoFocus={false}
            fieldWidth="15.5%"
            style={{ fontFamily: "sans-serif" }}
            onComplete={(e) => {
              setShowAnmeldenButton(true)
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          Du hast keinen Code erhalten? Dann probiere es <a href="/">hier</a> nochmal!
        </Typography>
      </Grid>

      <div style={{ marginTop: "auto" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <Button
              disabled={!showAnmeldenButton}
              color="primary"
              variant="contained"
              style={{ marginBottom: "1.5rem", textTransform: "initial" }}
              onClick={onClick}
            >
              Anmelden
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Step2
