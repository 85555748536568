import React, { useState } from "react"
import { Button, TextField, InputAdornment, Grid } from "@material-ui/core"
import Person from "@material-ui/icons/Person"
import PhoneIcon from "@material-ui/icons/Phone"
import { makeStyles } from "@material-ui/core/styles"
import almaLogo from "../../logo/logo_with_text.png"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2),
  },
}))

function Step1({ onClick }) {
  const classes = useStyles()

  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [showLosButton, setShowLosButton] = useState(false)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <img alt="alma" src={almaLogo} width="50%" />
          <Typography variant="body1" style={{ marginTop: "1rem" }}>
            Bitte melde dich mit deinem Namen und deiner Telefonnummer an. Danach bekommst du eine SMS mit einem Verifizierungscode
            zugesendet.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            className={classes.input}
            label=""
            variant="outlined"
            value={name}
            fullWidth
            placeholder="Vor- und Nachname"
            onChange={(e) => {
              setName(e.target.value)
              if (e.target.value !== "" && phoneNumber !== "") {
                setShowLosButton(true)
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person color="primary" />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className={classes.input}
            label=""
            placeholder="Telefonnummer"
            variant="outlined"
            type="tel"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value)
              if (e.target.value !== "" && name !== "") {
                setShowLosButton(true)
              }
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: "auto" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <Button disabled={!showLosButton} color="primary" variant="contained" style={{ marginBottom: "1.5rem" }} onClick={onClick}>
              Los gehts!
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Step1
