import React from "react"
import Grid from "@material-ui/core/Grid"

const Step4 = () => (
  <Grid
    item
    xs={12}
    style={{ backgroundColor: "#ECF1F5", marginTop: "4rem", padding: "1rem", marginBottom: "2rem" }}
    id="daten-praxis-infobox"
  >
    <p>Deine Daten wurden erfolgreich an die Praxis von Frau Dr. Haller übermittelt.</p>

    <p style={{ textAlign: "center" }}>
      <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Artboard">
            <circle id="Oval" stroke="#605E61" fillRule="nonzero" cx="16" cy="16" r="15"></circle>
            <g id="checkmark" transform="translate(5.000000, 9.000000)" fill="#605E61">
              <polygon
                id="Fill-95"
                points="7.88454454 14.1925586 -1.52944324e-12 6.82765625 1.20604602 5.65015625 7.88454454 11.8358919 21.0334395 3.55271368e-15 22.2394855 1.1775"
              ></polygon>
            </g>
          </g>
        </g>
      </svg>
    </p>

    <p>
      Tippe <a style={{ fontWeight: 500, textDecoration: "underline" }}>hier</a> um deine übermittelten Daten einzusehen.
    </p>
  </Grid>
)

export default Step4
