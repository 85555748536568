import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, Box, Typography, Button } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import CheckboxText from "../../components/CheckboxText"

function SearchGuideForMe() {
  const history = useHistory()
  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)
  const [check4, setCheck4] = useState(false)

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar back="/search/guide/me/5" text="Einrichtungssuche" />
      <Box my={4} style={{ margin: "2rem", padding: "1rem" }}>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          Seit wann hast du die ersten Symptome?
        </Typography>
      </Box>

      <CheckboxText
        text={"heute"}
        checked={check1}
        onChange={(e) => {
          setCheck1(true)
          setCheck2(false)
          setCheck3(false)
          setCheck4(false)
        }}
      />
      <CheckboxText
        text={"seit 1-3 Tagen"}
        checked={check2}
        onChange={(e) => {
          setCheck1(false)
          setCheck2(true)
          setCheck3(false)
          setCheck4(false)
        }}
      />
      <CheckboxText
        text={"seit 4-7 Tagen"}
        checked={check3}
        onChange={(e) => {
          setCheck1(false)
          setCheck2(false)
          setCheck3(true)
          setCheck4(false)
        }}
      />
      <CheckboxText
        text={"seit über einer Woche"}
        checked={check4}
        onChange={(e) => {
          setCheck1(false)
          setCheck2(false)
          setCheck3(false)
          setCheck4(true)
        }}
      />

      <Box my={4} style={{ margin: "2rem", marginBottom: "100px", padding: "1rem" }}>
        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "0.75rem",
            color: "#4C7DA3",
          }}
          onClick={(e) => {
            history.push("/search/guide/me/7")
          }}
        >
          medizinische Einrichtung finden
        </Button>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default SearchGuideForMe
