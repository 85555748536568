import React from "react"
import { createSvgIcon } from "@material-ui/core/utils"

export default createSvgIcon(
  <g id="Group" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(1.000000, 1.000000)">
    <circle id="Oval" stroke="#4C7DA3" fillRule="nonzero" cx="11" cy="11" r="11"></circle>
    <g id="checkmark" transform="translate(3.000000, 6.000000)" fill="#4C7DA3">
      <polygon
        id="Fill-95"
        points="6.34886503 9.9934788 0 4.37540849 1.38666161 2.96240849 6.34886503 7.1654788 14.8127313 0 16.1993929 1.413"
      ></polygon>
    </g>
  </g>,
  "CheckboxChecked"
)
