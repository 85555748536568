import React from "react"
import { createSvgIcon } from "@material-ui/core/utils"

export default createSvgIcon(
  <g transform="translate(4, 0)">
    <path
      d="M3.5005,6.0022 C3.5005,4.6222 4.6205,3.5022 6.0005,3.5022 C7.3805,3.5022 8.5005,4.6222 8.5005,6.0022 C8.5005,7.3822 7.3805,8.5022 6.0005,8.5022 C4.6205,8.5022 3.5005,7.3822 3.5005,6.0022 M12.0005,6.0022 C12.0005,2.6882 9.3135,0.0022 6.0005,0.0022 C2.6865,0.0022 0.0005,2.6882 0.0005,6.0022 C0.0005,10.0022 6.0005,17.0022 6.0005,17.0022 C6.0005,17.0022 12.0005,10.0022 12.0005,6.0022"
      id="Fill-522"
    ></path>
  </g>,
  "MapPoint"
)
