import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Paper, Container, Box, Typography, Button, Grid, Checkbox } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import LensIcon from "@material-ui/icons/Lens"

function SearchGuideForMe() {
  const history = useHistory()
  const [p1, setP1] = useState(false)
  const [p2, setP2] = useState(false)
  const [p3, setP3] = useState(false)
  const [p4, setP4] = useState(false)
  const [p5, setP5] = useState(false)
  const [p6, setP6] = useState(false)
  const [p7, setP7] = useState(false)
  const [p8, setP8] = useState(false)
  const [p9, setP9] = useState(false)
  const [p10, setP10] = useState(false)

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar back="/search/guide/me/4" text="Einrichtungssuche" />
      <Box my={4} style={{ margin: "1rem", padding: "1rem" }}>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          Wie stark sind deine Bauchschmerzen?
        </Typography>
      </Box>

      <Paper style={{ paddingTop: "20%", paddingBottom: "20%" }}>
        <Grid container>
          {/*<Grid item xs={1} />*/}
          {/*<Grid item xs={10}>*/}
          {/*  <Rating name="customized-10" defaultValue={4} max={10} />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={1} />*/}

          <Grid item xs={1} />
          <Grid item xs={5}>
            <Typography variant="body1" color="primary">
              Leicht
            </Typography>
          </Grid>
          <Grid item xs={5} style={{ textAlign: "right" }}>
            <Typography variant="body1" color="primary">
              Unerträglich
            </Typography>
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={1} />
          <Grid item xs={1}>
            <CB
              text="1"
              checked={p1}
              onClick={(e) => {
                setP1(true)
                setP2(false)
                setP3(false)
                setP4(false)
                setP5(false)
                setP6(false)
                setP7(false)
                setP8(false)
                setP9(false)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="2"
              checked={p2}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(false)
                setP4(false)
                setP5(false)
                setP6(false)
                setP7(false)
                setP8(false)
                setP9(false)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="3"
              checked={p3}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(true)
                setP4(false)
                setP5(false)
                setP6(false)
                setP7(false)
                setP8(false)
                setP9(false)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="4"
              checked={p4}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(true)
                setP4(true)
                setP5(false)
                setP6(false)
                setP7(false)
                setP8(false)
                setP9(false)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="5"
              checked={p5}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(true)
                setP4(true)
                setP5(true)
                setP6(false)
                setP7(false)
                setP8(false)
                setP9(false)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="6"
              checked={p6}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(true)
                setP4(true)
                setP5(true)
                setP6(true)
                setP7(false)
                setP8(false)
                setP9(false)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="7"
              checked={p7}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(true)
                setP4(true)
                setP5(true)
                setP6(true)
                setP7(true)
                setP8(false)
                setP9(false)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="8"
              checked={p8}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(true)
                setP4(true)
                setP5(true)
                setP6(true)
                setP7(true)
                setP8(true)
                setP9(false)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="9"
              checked={p9}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(true)
                setP4(true)
                setP5(true)
                setP6(true)
                setP7(true)
                setP8(true)
                setP9(true)
                setP10(false)
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <CB
              text="10"
              checked={p10}
              onClick={(e) => {
                setP1(true)
                setP2(true)
                setP3(true)
                setP4(true)
                setP5(true)
                setP6(true)
                setP7(true)
                setP8(true)
                setP9(true)
                setP10(true)
              }}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Paper>

      <Box my={4} style={{ margin: "2rem", marginBottom: "100px", padding: "1rem" }}>
        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "0.75rem",
            color: "#4C7DA3",
          }}
          onClick={(e) => {
            history.push("/search/guide/me/6")
          }}
        >
          Weiter
        </Button>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

const CB = ({ text, checked, onClick }) => (
  <>
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          color="primary"
          onClick={onClick}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<LensIcon />}
          name="checkedH"
        />
      }
    />
    <Typography variant="body1" color="primary">
      {text}
    </Typography>
  </>
)

export default SearchGuideForMe
