import React, { useEffect, useState } from "react"
// import {useHistory} from "react-router-dom"
import {
  Container,
  Box,
  Paper,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  List,
  Avatar,
  IconButton,
  Typography,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavBar from "../../components/NavBar"
import ChatContainer from "../../components/ChatContainer"
import ChatAlma from "../../components/ChatAlma"
import ChatUser from "../../components/ChatUser"
import AppBar from "../../components/AppBar"
import almaLogo from "../../logo/logo.png"
import ChatInput from "../../components/ChatInput"

const answer = [
  {
    text: "Zahnarztpraxis Frau Dr. Marx",
    subline: "Entfernung 1,0 km",
  },
  {
    text: "Zahnarztpraxis Frau Dr. Zimmermann",
    subline: "Entfernung 1,2 km",
  },
  {
    text: "Zahnarztpraxis Herr Dr. Paul",
    subline: "Entfernung 1,2 km",
  },
  {
    text: "Zahnarztpraxis Herr Dr. Wünsch",
    subline: "Entfernung 0,9 km",
  },
  {
    text: "Zahnarztpraxis Frau Dr. Bröse",
    subline: "Entfernung 1,5 km",
  },
]

function Ask() {
  // const history = useHistory()
  const [step, setStep] = useState(-1)

  useEffect(() => {
    console.log("EFFECT")
    setTimeout(() => {
      console.log("RUN")
      setStep(0)
    }, 700)
  }, [])

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar />

      <Box
        my={4}
        onClick={(e) => {
          setStep(step + 1)
        }}
      >
        <ChatContainer>
          {step === -1 ? null : null}

          {step >= 0 ? (
            <ChatAlma>
              <img alt="alma" src={almaLogo} width="60px" />
              <Typography variant="body1">
                Hallo,
                <br />
                wie kann ich dir behilflich sein? Hast du eine Frage oder suchst du etwas?
              </Typography>
            </ChatAlma>
          ) : null}

          {step > 0 ? (
            <ChatUser>
              <Typography variant="body1">Ich habe Zahnschmerzen</Typography>
            </ChatUser>
          ) : null}

          {step > 1 ? (
            <ChatAlma>
              <Typography variant="body1">
                Dann solltest du einen Zahnarzt besuchen. Brauchst du Hilfe, um einen Zahnarzt in deiner Nähe zu finden?
              </Typography>
            </ChatAlma>
          ) : null}
        </ChatContainer>

        {step > 2 ? (
          <Typography variant="body1" style={{ margin: "2rem" }}>
            Wir haben 4 Zahnarztpraxen im Umkreis von 1,5 km gefunden, davon sprechen 3 Praxen englisch:
          </Typography>
        ) : null}

        {step > 3 ? (
          <Paper style={{ marginBottom: "80px" }}>
            <List dense={true}>
              {answer.map((chat, index) => (
                <>
                  <ListItem
                    style={{ marginTop: "0.3rem", marginBottom: "0.3rem" }}
                    button
                    onClick={(e) => {
                      // handleClick(index)
                    }}
                  >
                    <ListItemAvatar style={{ marginRight: "0.5rem" }}>
                      <Avatar src="/icons/pngs/Zahnarzt_200h.png" />
                    </ListItemAvatar>
                    <ListItemText primary={chat.text} secondary={chat.subline} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="comments">
                        <NavigateNextIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </Paper>
        ) : null}
      </Box>

      <ChatInput />
      <NavBar active={0} />
    </Container>
  )
}

export default Ask
