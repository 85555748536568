import React from "react"
import { fade, makeStyles } from "@material-ui/core/styles"
import { Box, InputBase } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1.5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#0d5a87",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "small",
    // [theme.breakpoints.up('md')]: {
    //   width: '30ch',
    // },
  },
}))

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "text.primary",
  // m: 1,
  paddingTop: 1,
  paddingBottom: 1,
  border: 1,
}

function SearchInput({ placeholder }) {
  const classes = useStyles()

  return (
    <Box borderRadius="borderRadius" {...defaultProps}>
      <div className={classes.search} id="search-input">
        <div className={classes.searchIcon}>
          <SearchIcon color="primary" />
        </div>
        <InputBase
          fullWidth
          placeholder={placeholder}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
      </div>
    </Box>
  )
}

export default SearchInput
