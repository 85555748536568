import React from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Box,
  Button,
  Typography,
  Divider,
  IconButton,
  List,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import IconMapPointCheck from "../../icons/MapPointCheck"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "80px",
  },
  listItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const searchList = [
  {
    text: "Arzt / Arztpraxis",
    iconSrc: "/icons/pngs/icon_arzt_200h.png",
  },
  {
    text: "Ergotherapie",
    iconSrc: "/icons/pngs/icon_ergotherapie_200h.png",
  },
  {
    text: "Krankenhaus",
    iconSrc: "/icons/pngs/icon_krankenhaus_200h.png",
  },
  {
    text: "Physiotherapie",
    iconSrc: "/icons/pngs/icon_physiotherapie_200h.png",
  },
  {
    text: "Psychologische Einrichtung",
    iconSrc: "/icons/pngs/icon_psychologie_200h.png",
  },
]

function Search() {
  const classes = useStyles()
  const history = useHistory()

  const handleToggle = (e) => {
    if (e === 0) {
      history.push("/search/arztpraxis")
    }
  }

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar />
      <Box my={4}>
        <Grid container style={{ marginTop: "3rem", marginBottom: "1rem" }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Typography variant="body1" style={{ marginBottom: "2rem", fontWeight: 400 }}>
              Ich benötige Hilfe um die richtige Einrichtung (z.B. Arztpraxis) zu finden:
            </Typography>

            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              style={{
                // backgroundColor: "white",
                // color: "black",
                justifyContent: "flex-start",
                textTransform: "initial",
                padding: "0.5rem",
                marginTop: "0.5rem",
              }}
              startIcon={
                <IconMapPointCheck
                  style={{
                    color: "white",
                    fontSize: "24px",
                    marginLeft: "2px",
                    paddingTop: "6px",
                  }}
                />
              }
              onClick={(e) => {
                history.push("/search/guide")
              }}
            >
              Suche beginnen
            </Button>

            {/*<SearchInput placeholder={"Einrichtungssuche"}/>*/}

            <Typography style={{ marginTop: "4rem", fontWeight: 400 }}>Ich suche:</Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>

        <List dense className={classes.root}>
          {searchList.map((value, index) => {
            return (
              <>
                <ListItem
                  key={index}
                  button
                  onClick={(e) => {
                    handleToggle(index)
                  }}
                  className={classes.listItem}
                >
                  <ListItemAvatar>
                    <Avatar src={value.iconSrc} style={{ width: "32px", height: "32px" }} />
                  </ListItemAvatar>
                  <ListItemText primary={value.text} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      <NavigateNextIcon color="primary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            )
          })}
        </List>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default Search
