import React from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Grid,
  ListItem,
  List,
  Divider,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import CloseIcon from "@material-ui/icons/Close"
import SettingsIcon from "@material-ui/icons/Settings"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import MeineDaten from "../icons/Meinedaten"
import Arzt from "../icons/Arzt"
import SOS from "../icons/SOS"
import Paragraph from "../icons/Paragraph"

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 400,
  },
}))

function NavBar({ back = "", text }) {
  const history = useHistory()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  // const handleMobileMenuOpen = (event) => {
  //   setMobileMoreAnchorEl(event.currentTarget)
  // }

  const menuId = "primary-search-account-menu"

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Grid container>
        <Grid item xs style={{ width: "100%", height: "80vh" }}>
          <List>
            <ListElementClose onClick={handleMenuClose} />
            <ListElement onClick={handleMenuClose} text={"Notfallkontakte"} icon={<SOS />} />
            <Divider />
            <ListElement onClick={handleMenuClose} text={"Meine Einrichtungen"} icon={<Arzt />} />
            <Divider />
            <ListElement onClick={handleMenuClose} text={"Meine persönlichen Daten"} icon={<MeineDaten />} />
            <Divider />
            <ListElement onClick={handleMenuClose} text={"Einstellungen"} icon={<SettingsIcon />} />
            <Divider />
            <ListElement onClick={handleMenuClose} text={"Datenschutzerklärung & Nutzungsbedingungen"} icon={<Paragraph />} />
            <Divider />
            <ListElement
              text={"Logout"}
              icon={<ExitToAppIcon />}
              onClick={(e) => {
                localStorage.clear()
                setAnchorEl(null)
                handleMobileMenuClose()
              }}
            />
          </List>
        </Grid>
      </Grid>
    </Menu>
  )

  return (
    <>
      <AppBar position="sticky" style={{ backgroundColor: "white", color: "black" }}>
        <Toolbar>
          {back !== "" ? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="primary"
              aria-label="menu"
              onClick={(e) => {
                history.push(back)
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
          ) : null}

          <Typography variant="body2" className={classes.title} color="primary">
            {text}
          </Typography>
          <IconButton color="primary" aria-label="menu" onClick={handleProfileMenuOpen}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  )
}

const ListElement = ({ text, icon, onClick }) => (
  <ListItem>
    <ListItemAvatar>
      {/*<Avatar>{icon}</Avatar>*/}
      {icon}
    </ListItemAvatar>
    <ListItemText primary={text} style={{ color: "black", fontStyle: "normal" }} />
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="delete" onClick={onClick}>
        <NavigateNextIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
)

const ListElementClose = ({ onClick }) => (
  <ListItem style={{ marginBottom: "2rem" }}>
    {/*<ListItemAvatar>*/}
    {/*  <Avatar>{icon}</Avatar>*/}
    {/*</ListItemAvatar>*/}
    {/*<ListItemText primary={text} />*/}
    <ListItemSecondaryAction>
      <IconButton edge="end" aria-label="delete" onClick={onClick}>
        <CloseIcon />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
)

export default NavBar
