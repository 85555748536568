import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-2.000000, -2.000000)">
          <g id="englisch" transform="translate(2.000000, 2.000000)">
            <circle id="Oval" fill="#F0F0F0" cx="10" cy="10" r="10"></circle>
            <g id="Group" transform="translate(0.312500, 0.312500)" fill="#0052B4">
              <path
                d="M1.7546875,3.59929687 C0.969179688,4.62128906 0.376875,5.79921875 0.0319921875,7.07886719 L5.23425781,7.07886719 L1.7546875,3.59929687 Z"
                id="Path"
              ></path>
              <path
                d="M19.3430078,7.07886719 C18.998125,5.79925781 18.4057813,4.62132812 17.6203125,3.59933594 L14.1408203,7.07886719 L19.3430078,7.07886719 Z"
                id="Path"
              ></path>
              <path
                d="M0.0319921875,12.29625 C0.376914063,13.5758594 0.96921875,14.7537891 1.7546875,15.7757422 L5.23414063,12.29625 L0.0319921875,12.29625 Z"
                id="Path"
              ></path>
              <path
                d="M15.7757031,1.75472656 C14.7537109,0.96921875 13.5758203,0.376914063 12.2961719,0.0319921875 L12.2961719,5.23421875 L15.7757031,1.75472656 Z"
                id="Path"
              ></path>
              <path
                d="M3.59929687,17.6202734 C4.62128906,18.4057813 5.79921875,18.9980859 7.07882812,19.3430078 L7.07882812,14.1408203 L3.59929687,17.6202734 Z"
                id="Path"
              ></path>
              <path
                d="M7.07878906,0.0319921875 C5.79917969,0.376914062 4.62125,0.96921875 3.59929687,1.7546875 L7.07878906,5.23417969 L7.07878906,0.0319921875 Z"
                id="Path"
              ></path>
              <path
                d="M12.2962109,19.3430078 C13.5758203,18.9980859 14.75375,18.4057813 15.7757031,17.6203125 L12.2962109,14.1408203 L12.2962109,19.3430078 Z"
                id="Path"
              ></path>
              <path
                d="M14.1408203,12.29625 L17.6203125,15.7757813 C18.4057813,14.7538281 18.998125,13.5758594 19.3430078,12.29625 L14.1408203,12.29625 Z"
                id="Path"
              ></path>
            </g>
            <g id="Group" fill="#D80027">
              <path
                d="M19.9153516,8.69566406 L11.3044141,8.69566406 L11.304375,8.69566406 L11.304375,0.0846484375 C10.8773828,0.0290625 10.4420703,0 10,0 C9.55785156,0 9.12261719,0.0290625 8.69566406,0.0846484375 L8.69566406,8.69558594 L8.69566406,8.695625 L0.0846484375,8.695625 C0.0290625,9.12261719 0,9.55792969 0,10 C0,10.4421484 0.0290625,10.8773828 0.0846484375,11.3043359 L8.69558594,11.3043359 L8.695625,11.3043359 L8.695625,19.9153516 C9.12261719,19.9709375 9.55785156,20 10,20 C10.4420703,20 10.8773828,19.9709766 11.3043359,19.9153516 L11.3043359,11.3044141 L11.3043359,11.304375 L19.9153516,11.304375 C19.9709375,10.8773828 20,10.4421484 20,10 C20,9.55792969 19.9709375,9.12261719 19.9153516,8.69566406 Z"
                id="Path"
              ></path>
              <path
                d="M12.6087109,12.6087109 L17.0710547,17.0710937 C17.2762891,16.8659375 17.4720703,16.6514453 17.6588672,16.4291016 L13.8384766,12.6087109 L12.6087109,12.6087109 Z"
                id="Path"
              ></path>
              <path
                d="M7.39128906,12.60875 L7.39121094,12.60875 L2.92890625,17.0710547 C3.1340625,17.2762891 3.34855469,17.4720703 3.57089844,17.6588672 L7.39128906,13.8383984 L7.39128906,12.60875 Z"
                id="Path"
              ></path>
              <path
                d="M7.39128906,7.39136719 L7.39128906,7.39128906 L2.92894531,2.92890625 C2.72371094,3.1340625 2.52792969,3.34855469 2.34113281,3.57089844 L6.1615625,7.39132813 L7.39128906,7.39136719 Z"
                id="Path"
              ></path>
              <path
                d="M12.6087109,7.39136719 L17.0710937,2.92894531 C16.8659375,2.72371094 16.6514453,2.52792969 16.4291016,2.34117188 L12.6087109,6.16160156 L12.6087109,7.39136719 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default Icon
