import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" fill="#605E61">
          <g id="meine-daten">
            <g id="Fill-877">
              <path d="M9,13.4146 L5.293,9.7066 L6.707,8.2936 L9,10.5866 L13.293,6.2936 L14.707,7.7066 L9,13.4146 Z M18,3.0006 L10,3.0006 L8.553,1.1066 C8.214,0.4286 7.522,0.0006 6.764,0.0006 L1,0.0006 C0.447,0.0006 0,0.4476 0,1.0006 L0,15.0006 C0,16.1046 0.896,17.0006 2,17.0006 L18,17.0006 C19.104,17.0006 20,16.1046 20,15.0006 L20,5.0006 C20,3.8956 19.104,3.0006 18,3.0006 L18,3.0006 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default Icon
