import React from "react"
import { useHistory } from "react-router-dom"
import { Container, Box, Typography, Button } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"

function SearchGuideForMe() {
  const history = useHistory()

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar back="/search/guide" text="Einrichtungssuche" />
      <Box my={4} style={{ margin: "2rem", marginBottom: "110px", padding: "1rem" }}>
        <Typography variant="body1" style={{ fontWeight: 400, marginBottom: "90%" }}>
          Hast du akute Beschwerden?
        </Typography>

        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "0.75rem",
            color: "#4C7DA3",
          }}
          onClick={(e) => {
            history.push("/search/guide/me/3")
          }}
        >
          Ja, Beschwerden sind akut
        </Button>

        <Button
          fullWidth
          variant="outlined"
          style={{
            backgroundColor: "white",
            textTransform: "initial",
            marginBottom: "100px",
            color: "#4C7DA3",
          }}
        >
          Nein, keine akuten Beschwerden
        </Button>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default SearchGuideForMe
