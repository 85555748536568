import React from "react"
import Grid from "@material-ui/core/Grid"

function ChatAlma({ children }) {
  return (
    <>
      <Grid
        item
        xs={11}
        style={{
          border: "1px solid #979797",
          borderRadius: "6px",
          padding: "0.75rem",
          marginBottom: "0.5rem",
          backgroundColor: "white",
        }}
      >
        {children}
      </Grid>
      <Grid item xs={1} />
    </>
  )
}

export default ChatAlma
