import React from "react"
import Grid from "@material-ui/core/Grid"

function ChatContainer({ children }) {
  return (
    <Grid container style={{ padding: "1rem" }}>
      {children}
    </Grid>
  )
}

export default ChatContainer
