import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" fill="#605E61">
          <g id="Arzt">
            <g id=" Combined-Shape">
              <path
                d=" M6.82133239
          ,2.87573689 C6.77477423,2.69167837 6.75,2.49874715 6.75,2.3 C6.75,1.03155 7.759125,0 9,0 C10.240875,0 11.25,1.03155 11.25,2.3 C11.25,2.49874715 11.2252258,2.69167837 11.1786676,2.87573689 C12.5620265,3.66127156 13.5,5.17053747 13.5,6.9 C13.5,9.4369 11.48175,11.5 9,11.5 C6.51825,11.5 4.5,9.4369 4.5,6.9 C4.5,5.17053747 5.43797353,3.66127156 6.82133239,2.87573689 Z
           M9,3.45 C9.6204375,3.45 10.125,2.934225 10.125,2.3 C10.125,1.665775 9.6204375,1.15 9,1.15 C8.3795625,1.15 7.875,1.665775 7.875,2.3 C7.875,2.934225 8.3795625,3.45 9,3.45 Z
           M6.2408436,4.6 L5.625,5.75 L12.375,5.75 L11.8541621,4.6 L6.2408436,4.6 Z"
              ></path>
              <path
                d="
           M0,19.55 C0,15.48705 3.70125,12.65 9,12.65 C14.29875,12.65 18,15.48705 18,19.55 L18,23 L0,23 L0,19.55 Z
           M12.375,17.536925 L9.84375,17.536925 L9.84375,14.949425 L8.15625,14.949425 L8.15625,17.536925 L5.625,17.536925 L5.625,19.261925 L8.15625,19.261925 L8.15625,21.849425 L9.84375,21.849425 L9.84375,19.261925 L12.375,19.261925 L12.375,17.536925 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default Icon
