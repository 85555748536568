import React from "react"
import ChatContainer from "../../components/ChatContainer"
import ChatAlma from "../../components/ChatAlma"

const Step3 = () => (
  <ChatContainer>
    <ChatAlma>
      Liebe Frau Nowak, gerne bestätigen wir Ihnen den gewünschten Termin am 17.06.2020 um 8.30 Uhr. Bitte bringen Sie Ihre
      Versichertenkarte oder entstprechende Dokumente mit. Um uns auf Ihren Termin bestens Vorbereiten zu können bitten wir Sie, uns einige
      Ihrer Daten aus der Alma - Anwendung zu übermitteln. Dies ist hier direkt im Chat möglich.
      <br />
      <br />
      Beste Grüße und bis morgen, das Praxis-Team von Frau Dr. Haller
    </ChatAlma>
  </ChatContainer>
)

export default Step3
