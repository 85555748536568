import React from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Box,
  Typography,
  IconButton,
  List,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import AvatarGroup from "../../components/AvatarGroup"
import NavBar from "../../components/NavBar"
import AppBar from "../../components/AppBar"
import ManIcon from "../../icons/Man"
import WomanIcon from "../../icons/Woman"
import LanguageEnglishIcon from "../../icons/LanguageEnglish"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "80px",
  },
  listItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const searchList = [
  {
    text: "Arztpraxis Frau Dr. Haller",
    subText: "Entfernung 0,8km",
    iconsLeft: ["/icons/pngs/Allgemeinarzt_200h.png"],
    iconsRight: [<WomanIcon />, <LanguageEnglishIcon />],
  },
  {
    text: (
      <span>
        Gemeinschaftspraxis <br />
        Frau Dr. Lieber / Herr Dr. Scholz
      </span>
    ),
    subText: "Entfernung 1,0km",
    iconsLeft: ["/icons/pngs/Allgemeinarzt_200h.png"],
    iconsRight: [<ManIcon />, <WomanIcon />, <LanguageEnglishIcon />],
  },
  {
    text: (
      <span>
        Gemeinschaftspraxis <br />
        Frau Dr. Stefan / Herr Dr. Müller
      </span>
    ),
    subText: "Entfernung 0,5km",
    iconsLeft: ["/icons/pngs/Allgemeinarzt_200h.png", "/icons/pngs/Anaesthesist_200h.png"],
    iconsRight: [<ManIcon />, <WomanIcon />],
  },
  {
    text: "Arztpraxis Herr Dr. Brandtstätter",
    subText: "Entfernung 0,7km",
    iconsLeft: ["/icons/pngs/Allgemeinarzt_200h.png"],
    iconsRight: [<ManIcon />],
  },
  {
    text: "Arztpraxis Frau Dr. Großer",
    subText: "Entfernung 1,2km",
    iconsLeft: ["/icons/pngs/Allgemeinarzt_200h.png"],
    iconsRight: [<WomanIcon />],
  },
  {
    text: (
      <span>
        Gemeinschaftspraxis <br />
        Herr Dr. Buchholz / Herr Dr. Hochfeld
      </span>
    ),
    subText: "Entfernung 2,4km",
    iconsLeft: ["/icons/pngs/Allgemeinarzt_200h.png"],
    iconsRight: [<ManIcon />, <WomanIcon />],
  },
  {
    text: "Arztpraxis Herr Prof. Dr. Vollmer",
    subText: "Entfernung 4,2km",
    iconsLeft: ["/icons/pngs/Allgemeinarzt_200h.png"],
    iconsRight: [<ManIcon />],
  },
]

function Search() {
  const classes = useStyles()
  const history = useHistory()

  const handleToggle = (e) => {
    if (e === 0) {
      history.push("/search/profile")
    }
  }

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar text={"Allgemeinarztpraxis"} back={"/search/arztpraxis"} />
      <Box my={4}>
        <Grid container style={{ marginTop: "3rem", marginBottom: "1rem" }}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant="body2">
              Wir haben <b>8 Allgemeinarztpraxen</b> im Umkreis von 1,5 km gefunden, davon sprechen <b>2 Praxen englisch</b>:
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>

        <List dense className={classes.root}>
          {searchList.map((value, index) => {
            return (
              <>
                <ListItem
                  key={index}
                  button
                  onClick={(e) => {
                    handleToggle(index)
                  }}
                  className={classes.listItem}
                >
                  <ListItemAvatar>
                    <AvatarGroup avatars={value.iconsLeft} />
                  </ListItemAvatar>
                  <ListItemText primary={value.text} secondary={value.subText} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      {value.iconsRight.map((iconRight, iconRightIndex) => (
                        <div key={iconRightIndex} style={{ marginLeft: "-6px" }}>
                          {iconRight}
                        </div>
                      ))}
                      <NavigateNextIcon color="primary" style={{ marginTop: "-14px" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {/*<Divider/>*/}
              </>
            )
          })}
        </List>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default Search
