import React from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core"
import NavIconAlma from "../icons/NavAlma"
import NavIconChat from "../icons/NavChat"
import NavIconSuche from "../icons/NavSuche"
import NavIconInfo from "../icons/NavInfo"

const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: "80px",
    borderTop: "1px solid #888888",
    zIndex: 99999,
  },
})

function NavBar({ active }) {
  const classes = useStyles()
  const history = useHistory()
  const [value, setValue] = React.useState(active)

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Frag Alma"
        icon={<NavIconAlma />}
        style={{ backgroundColor: active === 0 ? "#F1A868" : "white" }}
        onClick={(e) => {
          history.push("/ask")
        }}
      />
      <BottomNavigationAction
        label="Suchen"
        icon={<NavIconSuche />}
        style={{ backgroundColor: active === 1 ? "#F1A868" : "white" }}
        onClick={(e) => {
          history.push("/search/intro")
        }}
      />
      <BottomNavigationAction
        label="Nachrichten"
        icon={<NavIconChat />}
        style={{ backgroundColor: active === 2 ? "#F1A868" : "white" }}
        onClick={(e) => {
          history.push("/chat")
        }}
      />
      <BottomNavigationAction
        label="Info"
        icon={<NavIconInfo />}
        style={{ backgroundColor: active === 3 ? "#F1A868" : "white" }}
        onClick={(e) => {
          history.push("/info")
        }}
      />
    </BottomNavigation>
  )
}

export default NavBar
