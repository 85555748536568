import React from "react"
import { createSvgIcon } from "@material-ui/core/utils"

export default createSvgIcon(
  <g>
    <g
      id="krankenversicherung"
      transform="translate(8.500000, 12.000000) scale(-1, 1) translate(-8.500000, -12.000000) translate(0.000000, 2.000000)"
      fill="#4C7DA3"
      fillRule="nonzero"
    >
      <path
        d="M16.9799149,5.6074684 L16.9795631,5.5891707 C16.9701053,5.11050576 16.9611554,4.6583875 16.9611554,4.22340458 C16.9611554,3.56446227 16.4023975,3.02839204 15.7155673,3.02839204 C13.0580573,3.02839204 11.0411042,2.30147076 9.36803515,0.740766613 C8.88111027,0.286436127 8.11818705,0.286398631 7.63130126,0.740729118 C5.95831041,2.30147076 3.94155271,3.02839204 1.2844335,3.02839204 C0.597603282,3.02839204 0.0388453642,3.56446227 0.0388453642,4.22340458 C0.0388453642,4.66768633 0.0297392164,5.12430403 0.0200859181,5.60784335 C-0.0248585881,7.86509759 -0.0807851867,10.6743201 0.901858048,13.2243001 C1.44216918,14.6264741 2.26027644,15.8449962 3.33347308,16.8460456 C4.55576824,17.986165 6.15669155,18.8905515 8.09161117,19.5340083 C8.22320087,19.5778028 8.36045748,19.6 8.49962912,19.6 C8.63895709,19.6 8.77629187,19.5777653 8.90756891,19.5340458 C10.8427621,18.890514 12.4438027,17.9861275 13.6662151,16.8459706 C14.7394899,15.8448837 15.5577144,14.6263241 16.0980646,13.2241126 C17.080786,10.6739826 17.0248594,7.86472264 16.9799149,5.6074684 Z M14.9982921,12.834049 C13.961989,15.5230991 11.8440866,17.3670427 8.52319568,18.4713541 C8.50803179,18.4763785 8.49138278,18.476491 8.47567175,18.4712416 C5.15536704,17.3670802 3.03766007,15.5232116 2.00151333,12.834274 C1.09566658,10.483469 1.14698148,7.90461763 1.19227772,5.62932816 C1.20204827,5.14035202 1.21127166,4.67848498 1.21127166,4.22340458 C1.21127166,4.18474693 1.24406161,4.15325089 1.28439442,4.15325089 C4.26632562,4.15325089 6.54282349,3.3251673 8.44866596,1.54714042 C8.46293096,1.53382959 8.4812605,1.52715543 8.49959004,1.52715543 C8.51791958,1.52715543 8.53628821,1.53386709 8.55059228,1.54717792 C10.456552,3.3251673 12.7332453,4.15325089 15.7155282,4.15325089 C15.7558219,4.15325089 15.788651,4.18474693 15.788651,4.22340458 C15.788651,4.66907366 15.797679,5.12636627 15.8072932,5.61050552 L15.8076449,5.62887821 L15.8076449,5.62891571 C15.8529802,7.90424268 15.9042951,10.4831316 14.9982921,12.834049 Z"
        id="Shape"
        fillOpacity="0.695595562"
      ></path>
      <path
        d="M12.3912289,8.33896316 L10.5857491,8.33896316 L10.5857491,6.55451071 C10.5857491,6.24827293 10.3345194,6 10.0246345,6 L7.7851886,6 C7.4753037,6 7.22407397,6.24827293 7.22407397,6.55451071 L7.22407397,8.33896316 L5.41825748,8.33896316 C5.10837258,8.33896316 4.85714286,8.58723609 4.85714286,8.89347387 L4.85714286,11.1065631 C4.85714286,11.4128009 5.10837258,11.6610738 5.41825748,11.6610738 L7.22407397,11.6610738 L7.22407397,13.4454893 C7.22407397,13.7517271 7.4753037,14 7.7851886,14 L10.0246345,14 C10.3345194,14 10.5857491,13.7517271 10.5857491,13.4454893 L10.5857491,11.6610738 L12.3912663,11.6610738 C12.7011512,11.6610738 12.952381,11.4128009 12.952381,11.1065631 L12.952381,8.89347387 C12.9523435,8.58719912 12.7011138,8.33896316 12.3912289,8.33896316 Z M11.8301143,10.5520524 L10.0246345,10.5520524 C9.71474957,10.5520524 9.46351985,10.8003253 9.46351985,11.1065631 L9.46351985,12.8909786 L8.34630322,12.8909786 L8.34630322,11.1065631 C8.34630322,10.8003253 8.0950735,10.5520524 7.7851886,10.5520524 L5.97937211,10.5520524 L5.97937211,9.44798458 L7.7851886,9.44798458 C8.0950735,9.44798458 8.34630322,9.19971165 8.34630322,8.89347387 L8.34630322,7.10902143 L9.46351985,7.10902143 L9.46351985,8.89347387 C9.46351985,9.19971165 9.71474957,9.44798458 10.0246345,9.44798458 L11.8301517,9.44798458 L11.8301517,10.5520524 L11.8301143,10.5520524 Z"
        id="Shape"
        fillOpacity="0.702728714"
      ></path>
    </g>
    <g id="welt" transform="translate(10.000000, 0.000000)">
      <circle id="Oval" fill="#0052B4" cx="6" cy="6" r="6"></circle>
      <g id="Group" transform="translate(2.343750, 2.343750)" fill="#FFDA44">
        <polygon
          id="Path"
          points="3.65627344 0.004078125 3.85054688 0.601945313 4.47914063 0.601945313 3.97057031 0.9714375 4.16484375 1.56930469 3.65627344 1.19978906 3.14767969 1.56930469 3.34195313 0.9714375 2.83338281 0.601945313 3.462 0.601945313"
        ></polygon>
        <polygon
          id="Path"
          points="1.07376562 1.07376562 1.63392188 1.35914063 2.07841406 0.914671875 1.98004688 1.53553125 2.54017969 1.82092969 1.91929688 1.91927344 1.82095312 2.54017969 1.53555469 1.98004688 0.914695312 2.07841406 1.3591875 1.63392187"
        ></polygon>
        <polygon
          id="Path"
          points="0.0041015625 3.65625 0.60196875 3.46197656 0.60196875 2.83335938 0.9714375 3.34195313 1.56932812 3.14767969 1.19978906 3.65625 1.56932812 4.16482031 0.9714375 3.97057031 0.60196875 4.47914063 0.60196875 3.85052344"
        ></polygon>
        <polygon
          id="Path"
          points="1.07376562 6.23873438 1.35916406 5.67857813 0.914695312 5.23408594 1.53557813 5.33247656 1.82092969 4.77234375 1.91929688 5.39322656 2.54015625 5.49157031 1.98009375 5.77696875 2.07841406 6.39782813 1.63392188 5.95333594"
        ></polygon>
        <polygon
          id="Path"
          points="3.65627344 7.30842188 3.46197656 6.71055469 2.83338281 6.71055469 3.34197656 6.3410625 3.14767969 5.74324219 3.65627344 6.11271094 4.16484375 5.74324219 3.97057031 6.3410625 4.47914063 6.71055469 3.85052344 6.71055469"
        ></polygon>
        <polygon
          id="Path"
          points="6.23875781 6.23873437 5.678625 5.95335938 5.23410938 6.39785156 5.33247656 5.77694531 4.77239063 5.49157031 5.39325 5.39322656 5.49159375 4.77234375 5.77696875 5.33247656 6.39782813 5.23408594 5.95333594 5.678625"
        ></polygon>
        <polygon
          id="Path"
          points="7.30842188 3.65625 6.71055469 3.85052344 6.71055469 4.47914063 6.3410625 3.97054688 5.74324219 4.16482031 6.11273438 3.65625 5.74324219 3.14767969 6.34108594 3.34195313 6.71055469 2.83335938 6.71055469 3.462"
        ></polygon>
        <polygon
          id="Path"
          points="6.23875781 1.07376562 5.95335938 1.63392188 6.39785156 2.07841406 5.77694531 1.98002344 5.49159375 2.54015625 5.39325 1.91927344 4.77239063 1.82090625 5.33247656 1.53553125 5.23410938 0.914695312 5.67864844 1.35916406"
        ></polygon>
      </g>
    </g>
  </g>,
  "versicherungEU"
)
