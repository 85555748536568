import React from "react"
import { Switch, Route } from "react-router-dom"
import { CssBaseline } from "@material-ui/core"
import { ThemeProvider, makeStyles } from "@material-ui/core/styles"
import theme from "./theme"
// import Home from "./views/Home"
import Signup from "./views/Signup"
import SignupProfile from "./views/SignupProfile"
import Settings from "./views/Settings"
import Ask from "./views/Ask"
import Search from "./views/Search"
import SearchIntro from "./views/SearchIntro"
import SearchArztpraxis from "./views/SearchArztpraxis"
import SearchResult from "./views/SearchResult"
import SearchProfile from "./views/SearchProfile"
import Chat from "./views/Chat"
import ChatDrHAller1 from "./views/Chat_dr_haller_1"
import Info from "./views/Info"
import InfoVersicherung from "./views/InfoVersicherung"
import SearchGuide from "./views/SearchGuide"
import SearchGuideForMe from "./views/SearchGuideForMe"
import SearchGuideForMe2 from "./views/SearchGuideForMe2"
import SearchGuideForMe3 from "./views/SearchGuideForMe3"
import SearchGuideForMe4 from "./views/SearchGuideForMe4"
import SearchGuideForMe5 from "./views/SearchGuideForMe5"
import SearchGuideForMe5b from "./views/SearchGuideForMe5b"
import SearchGuideForMe6 from "./views/SearchGuideForMe6"
import SearchGuideForMe7 from "./views/SearchGuideForMe7"
import SearchGuideForMe8 from "./views/SearchGuideForMe8"
import SearchChat from "./views/SearchChat"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}))

function App() {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />

        <Switch>
          <Route exact path={"/"} component={Signup} />
          <Route exact path={"/signup"} component={Signup} />
          <Route exact path={"/signup/profile"} component={SignupProfile} />
          <Route exact path={"/settings"} component={Settings} /> {/* rename: account*/}
          <Route exact path={"/ask"} component={Ask} />
          <Route exact path={"/search/intro"} component={SearchIntro} />
          <Route exact path={"/search"} component={Search} />
          <Route exact path={"/search/arztpraxis"} component={SearchArztpraxis} />
          <Route exact path={"/search/arztpraxis/allgemeinaerzte"} component={SearchResult} />
          <Route exact path={"/search/Profile"} component={SearchProfile} />
          <Route exact path={"/search/guide"} component={SearchGuide} />
          <Route exact path={"/search/guide/me"} component={SearchGuideForMe} />
          <Route exact path={"/search/guide/me/2"} component={SearchGuideForMe2} />
          <Route exact path={"/search/guide/me/3"} component={SearchGuideForMe3} />
          <Route exact path={"/search/guide/me/4"} component={SearchGuideForMe4} />
          <Route exact path={"/search/guide/me/5"} component={SearchGuideForMe5} />
          <Route exact path={"/search/guide/me/5b"} component={SearchGuideForMe5b} />
          <Route exact path={"/search/guide/me/6"} component={SearchGuideForMe6} />
          <Route exact path={"/search/guide/me/7"} component={SearchGuideForMe7} />
          <Route exact path={"/search/guide/me/8"} component={SearchGuideForMe8} />
          <Route exact path={"/search/chat"} component={SearchChat} />
          <Route exact path={"/chat"} component={Chat} />
          <Route exact path={"/chat/dr-haller-1"} component={ChatDrHAller1} />
          <Route exact path={"/info"} component={Info} />
          <Route exact path={"/info/versicherung"} component={InfoVersicherung} />
        </Switch>
      </div>
    </ThemeProvider>
  )
}

export default App
