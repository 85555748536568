import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

function Icon(props) {
  return (
    <SvgIcon {...props}>
      <g id="icon_nav_suche" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle cx="9" cy="9" r="9" fill="#fff"></circle>
        <g id="search-icon" fill="#605E61" fillRule="nonzero">
          <path
            d="M17.1526587,15.0943396 L16.0686106,15.0943396 L15.6843911,14.7238422 C17.0291595,13.1595197 17.838765,11.1286449 17.838765,8.9193825 C17.838765,3.99313894 13.8456261,0 8.9193825,0 C3.99313894,0 0,3.99313894 0,8.9193825 C0,13.8456261 3.99313894,17.838765 8.9193825,17.838765 C11.1286449,17.838765 13.1595197,17.0291595 14.7238422,15.6843911 L15.0943396,16.0686106 L15.0943396,17.1526587 L20.1879883,22 L22.354248,20 L17.1526587,15.0943396 Z M9.2,16.4 C5.216,16.4 2,13.184 2,9.2 C2,5.216 5.216,2 9.2,2 C13.184,2 16.4,5.216 16.4,9.2 C16.4,13.184 13.184,16.4 9.2,16.4 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </SvgIcon>
  )
}

export default Icon
