import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Container, Box, Paper } from "@material-ui/core"
import NavBar from "../../components/NavBar"
import ChatContainer from "../../components/ChatContainer"
import ChatAlma from "../../components/ChatAlma"
import AppBar from "../../components/AppBar"
import CheckboxText from "../../components/CheckboxText"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import IconButton from "@material-ui/core/IconButton"
import Step1 from "./step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"

function Ask() {
  const history = useHistory()

  const [step, setStep] = useState(1)

  const [hide1, setHide1] = useState(false)
  const [hideDatenSenden, setHideDatenSenden] = useState(true)
  const [hide2, setHide2] = useState(false)

  const [fuerMich, setFuerMich] = useState(false)
  const [fuerAndere, setFuerAndere] = useState(false)
  const [checkDatum, setcheckDatum] = useState(0)

  const [datenPatient1, setDatenPatient1] = useState(true)
  const [datenPatient2, setDatenPatient2] = useState(true)
  const [datenPatient3, setDatenPatient3] = useState(true)
  const [datenPatient4, setDatenPatient4] = useState(true)
  const [datenPatient5, setDatenPatient5] = useState(true)
  const [datenPatient6, setDatenPatient6] = useState(true)
  const [datenPatient7, setDatenPatient7] = useState(true)

  const [datenTermin1, setDatenTermin1] = useState(true)
  const [datenTermin2, setDatenTermin2] = useState(true)
  const [datenTermin3, setDatenTermin3] = useState(true)
  const [datenTermin4, setDatenTermin4] = useState(true)

  const [termin1, setTermin1] = useState(false)
  const [termin2, setTermin2] = useState(false)
  const [termin3, setTermin3] = useState(false)

  const scrollTo = () => {
    setTimeout(() => {
      window.scrollBy({ top: 1000, left: 0, behavior: "smooth" })
    }, 90)
  }

  const next = () => {
    setStep(step + 1)
    scrollTo()
  }

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <AppBar text={"Arztpraxis Frau Dr. Haller"} back={"/search/profile"} />

      <Box my={4}>
        {step > 0 ? (
          <>
            <Step1 />
            {hide1 === false ? (
              <Paper>
                <Typography variant={"body1"} color="primary" style={{ paddingLeft: "2rem", paddingTop: "2rem", fontStyle: "italic" }}>
                  Ich benötige einen Termin:
                </Typography>

                <CheckboxText
                  text={"für mich selbst"}
                  checked={fuerMich}
                  onChange={(e) => {
                    setFuerMich(true)
                    setFuerAndere(false)
                  }}
                />
                <CheckboxText
                  text={"für eine andere Person"}
                  checked={fuerAndere}
                  onChange={(e) => {
                    setFuerMich(false)
                    setFuerAndere(true)
                  }}
                />

                <br />

                <CheckboxText
                  text={"17.06.2020 08.30 Uhr"}
                  checked={checkDatum === 1 ? true : false}
                  onChange={(e) => {
                    setcheckDatum(1)
                  }}
                />
                <CheckboxText
                  text={"19.06.2020 17.30 Uhr"}
                  checked={checkDatum === 2 ? true : false}
                  onChange={(e) => {
                    setcheckDatum(2)
                  }}
                />
                <CheckboxText
                  text={"22.06.2020 08.00 Uhr"}
                  checked={checkDatum === 3 ? true : false}
                  onChange={(e) => {
                    setcheckDatum(3)
                  }}
                />
                <CheckboxText
                  text={"24.06.2020 09.30 Uhr"}
                  checked={checkDatum === 4 ? true : false}
                  onChange={(e) => {
                    setcheckDatum(4)
                  }}
                />
                <CheckboxText
                  text={"nächste Notfallsprechstunde anzeigen"}
                  checked={checkDatum === 5 ? true : false}
                  onChange={(e) => {
                    setcheckDatum(5)
                  }}
                />

                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant={"body1"}
                      color="primary"
                      style={{
                        margin: "1rem",
                        fontStyle: "italic",
                        fontSize: "0.75rem",
                        marginBottom: "1rem",
                      }}
                    >
                      Bitte beachte, dass bei einem Terminanfrage für dich selbst Vor- und Nachname an die Arztpraxis übermittelt werden,
                      damit der Termin zugeordnet werden kann.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ textAlign: "center", marginBottom: "2rem" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        textTransform: "initial",
                      }}
                      onClick={(e) => {
                        setHide1(true)
                        next()

                        setTimeout(() => {
                          setStep(step + 2)
                          scrollTo()
                        }, 1800)
                      }}
                    >
                      Terminanfrage absenden
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ) : null}
          </>
        ) : null}

        {step > 1 ? <Step2 /> : null}

        {step > 2 ? (
          <>
            <Step3 />

            {hideDatenSenden === true ? (
              <Paper>
                <Grid container>
                  <Grid item xs={12} style={{ padding: "2rem" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      style={{ marginBottom: "0.5rem", textTransform: "initial" }}
                      onClick={(e) => {
                        setStep(step + 1)
                        setHideDatenSenden(false)
                        setTimeout(() => {
                          window.scrollBy({ top: 50, left: 0, behavior: "smooth" })
                        }, 200)
                      }}
                    >
                      Daten zum Prüfen öffnen
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "initial" }}
                      fullWidth
                      onClick={(e) => {
                        next()
                        setHideDatenSenden(false)
                      }}
                    >
                      Nein, ich möchte keine Daten senden
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ) : null}
          </>
        ) : null}

        {step > 3 ? (
          <>
            {hide2 === false ? (
              <Paper style={{ padding: "1rem" }}>
                <span style={{ float: "right" }} id="step3-close-btn">
                  <IconButton
                    aria-label="delete"
                    size="medium"
                    style={{ margin: "0", padding: "0" }}
                    onClick={(e) => {
                      // next()
                      // setHide2(true)
                      // setHideDatenSenden(false)
                      history.push("/search")
                    }}
                  >
                    <HighlightOffIcon fontSize="inherit" color="primary" />
                  </IconButton>
                </span>

                <div style={{ marginTop: "2rem" }}>
                  <Typography variant="caption" color="primary" style={{ marginTop: "3rem" }}>
                    Alle Daten, die nicht an die Praxis von Dr. Haller übermittelt werden sollen, können einfach abgewählt werden.
                  </Typography>
                </div>

                <Typography variant="body1" color="primary" style={{ marginTop: "2rem", fontStyle: "italic" }}>
                  Allgemeine Patienten - Daten:
                </Typography>

                <CheckboxText
                  checked={datenPatient1}
                  onClick={(e) => {
                    setDatenPatient1(!datenPatient1)
                  }}
                  text={"Geburtsdatum: 27.05.1992"}
                />
                <CheckboxText
                  checked={datenPatient2}
                  onClick={(e) => {
                    setDatenPatient2(!datenPatient2)
                  }}
                  text={"Alter: 28"}
                />
                <CheckboxText
                  checked={datenPatient3}
                  onClick={(e) => {
                    setDatenPatient3(!datenPatient3)
                  }}
                  text={"weiblich"}
                />
                <CheckboxText
                  checked={datenPatient4}
                  onClick={(e) => {
                    setDatenPatient4(!datenPatient4)
                  }}
                  text={"Vorerkrankungen / chronischen Erkrankungen: nein"}
                />
                <CheckboxText
                  checked={datenPatient5}
                  onClick={(e) => {
                    setDatenPatient5(!datenPatient5)
                  }}
                  text={"körperliche und / oder geistige Einschränkungen: nein"}
                />
                <CheckboxText
                  checked={datenPatient6}
                  onClick={(e) => {
                    setDatenPatient6(!datenPatient6)
                  }}
                  text={"kürzliche Verletzungen und / oder Operationen: nein"}
                />
                <CheckboxText
                  checked={datenPatient7}
                  onClick={(e) => {
                    setDatenPatient7(!datenPatient7)
                  }}
                  text={"schwanger: nein"}
                />

                <Typography color="primary" variant="body1" style={{ marginTop: "3rem", fontStyle: "italic" }}>
                  Daten zum Termin:
                </Typography>

                <CheckboxText
                  checked={datenTermin1}
                  onClick={(e) => {
                    setDatenTermin1(!datenTermin1)
                  }}
                  text={"akute Beschewerden im Oberbauch"}
                />
                <CheckboxText
                  checked={datenTermin2}
                  onClick={(e) => {
                    setDatenTermin2(!datenTermin2)
                  }}
                  text={"Symptome: Druckempfindlichkeit, Krämpfe, stechende Schmerzen "}
                />
                <CheckboxText
                  checked={datenTermin3}
                  onClick={(e) => {
                    setDatenTermin3(!datenTermin3)
                  }}
                  text={"leichte bis mäßige Bauchschmerzen "}
                />
                <CheckboxText
                  checked={datenTermin4}
                  onClick={(e) => {
                    setDatenTermin4(!datenTermin4)
                  }}
                  text={"erste Symptome seit 1-3 Tagen"}
                />

                <Grid container>
                  <Grid item xs={12} style={{ textAlign: "center", marginBottom: "2rem", marginTop: "2rem" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "initial" }}
                      onClick={(e) => {
                        setTimeout(() => {
                          setStep(step + 1)
                          setHide2(true)
                          setHideDatenSenden(false)

                          setTimeout(() => {
                            setStep(step + 2)
                          }, 1200)
                        }, 100)
                      }}
                    >
                      Daten an Praxis übermitteln
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ) : null}
          </>
        ) : null}

        {step > 4 ? (
          <Grid container>
            <Step4 />
          </Grid>
        ) : null}

        {step > 5 ? (
          <Grid container>
            <Grid item xs={12} style={{ backgroundColor: "white", paddingTop: "2rem" }}>
              <CheckboxText
                text="Termin absagen"
                checked={termin1}
                onClick={(e) => {
                  setTermin1(true)
                  setTermin2(false)
                  setTermin3(false)
                }}
              />
              <CheckboxText
                text="Termin verschieben"
                checked={termin2}
                onClick={(e) => {
                  setTermin1(false)
                  setTermin2(true)
                  setTermin3(false)
                }}
              />
              <CheckboxText
                text="Praxis kontaktieren"
                checked={termin3}
                onClick={(e) => {
                  setTermin1(false)
                  setTermin2(false)
                  setTermin3(true)
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                marginBottom: "2rem",
                backgroundColor: "white",
                paddingTop: "2rem",
                paddingBottom: "2rem",
              }}
            >
              <Button variant="outlined" color="primary" style={{ textTransform: "initial", width: "80%" }}>
                Nachricht senden
              </Button>
            </Grid>
          </Grid>
        ) : null}

        <div style={{ height: "80px" }}></div>
      </Box>
      <NavBar active={1} />
    </Container>
  )
}

export default Ask
