import React from "react"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import Fab from "@material-ui/core/Fab"
import { Grid } from "@material-ui/core"

const ChatInput = ({ onChange }) => (
  <div
    style={{
      width: "100%",
      height: "70px",
      backgroundColor: "white",
      position: "fixed",
      bottom: "70px",
      padding: "0.5rem",
    }}
  >
    <Grid container>
      <Grid item xs={10}>
        <InputBase
          placeholder="Nachricht"
          fullWidth
          multiline
          // rows={1}
          // rowsMin={1}
          // rowsMax={3}
          inputProps={{ "aria-label": "message" }}
          onChange={onChange}
          style={{
            border: "solid 1px #4C7DA3",
            borderRadius: "25px",
            paddingLeft: "0.75rem",
            height: "40px",
          }}
        />
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          style={{
            color: "#4C7DA3",
            backgroundColor: "white",
            border: "1px solid #4C7DA3",
            boxShadow: "0px 0px 0px 0px white",
          }}
        >
          <ArrowUpwardIcon fontSize="inherit" />
        </Fab>
      </Grid>
    </Grid>
  </div>
)

export default ChatInput
