import React from "react"
import { createSvgIcon } from "@material-ui/core/utils"

export default createSvgIcon(
  <g transform="translate(0.0, 4.0)">
    <path d="M10,5.8237 L18.965,0.2597 C18.678,0.0997 18.352,0.0007 18,0.0007 L2,0.0007 C1.648,0.0007 1.322,0.0997 1.035,0.2597 L10,5.8237 Z"></path>
    <path d="M10.5273,7.8501 C10.3653,7.9501 10.1833,8.0001 10.0003,8.0001 C9.8173,8.0001 9.6343,7.9501 9.4723,7.8501 L0.0033,1.9741 C0.0033,1.9831 0.0003,1.9921 0.0003,2.0001 L0.0003,11.0001 C0.0003,12.1031 0.8973,13.0001 2.0003,13.0001 L18.0003,13.0001 C19.1033,13.0001 20.0003,12.1031 20.0003,11.0001 L20.0003,2.0001 C20.0003,1.9921 19.9973,1.9831 19.9973,1.9741 L10.5273,7.8501 Z"></path>
  </g>,
  "Mail"
)
